import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import DataView from '../../components/DataView';
import { DataLoadCallback, useRemoteData, useRoute } from '../../model/Hooks';
import { Connection } from '../../model/Types';
import ConnectionForm from './Form';
import ConnectionsTable from './Table';

export function ConnectionCombo({ onDataLoad, ...props }: { onDataLoad?: DataLoadCallback<Connection[]> } & SelectProps) {
    const [rows] = useRemoteData<Connection[]>('Connection/List?showAll=true', d => {
        d = d.map(c => {
            const con = new Connection();
            Object.assign(con, c);
            return con;
        });

        return onDataLoad ? onDataLoad(d) : d;
    }, 'controller');

    return <FormControl fullWidth>
        <InputLabel id={'label-connectionId'}>Соединение</InputLabel>

        <Select key={rows?.length} labelId={'label-connectionId'} label="Соединение" name="connectionId" {...props}>
            {rows?.map(x => <MenuItem value={x.Id}>{x.Name}</MenuItem>)}
        </Select>
    </FormControl>;
}

export default function ConnectionView() {
    var [route] = useRoute();
    return <DataView<number, Connection>
        table={(onSelectRow) => <ConnectionsTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <ConnectionForm id={id} onSaved={e => route.setState('id', e.Id)} onDeleted={e => route.setState([['id']])} apiRef={apiRef} />}
    />;
}