import { AddRounded, HorizontalRuleRounded } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Parameter, SimpleType } from '../model/Types';

const typeIds: {
    [type: string]: {
        name: string,
        render: (p: Parameter) => React.ReactNode
    }
} = {
    number: {
        name: 'Число',
        render: p => <TextField label={'Значение по умолчанию' || p.Title || p.Name} name={p.Name} defaultValue={p.Number} fullWidth type="Number"
            onChange={e => p.Number = parseInt(e.target.value)}
        />
    },
    string: {
        name: 'Строка',
        render: p => <TextField label={'Значение по умолчанию' || p.Title || p.Name} name={p.Name} defaultValue={p.String} fullWidth onChange={e => p.String = e.target.value} />
    },
    date: {
        name: 'Дата',
        render: p => <DatePicker label={'Значение по умолчанию' || p.Title || p.Name} name={p.Name} defaultValue={p.Date ? dayjs(p.Date) : undefined} sx={{ width: '100%' }} onChange={v => p.Date = v?.toDate()} />
    }
}
const typeIdList: {
    type: string,
    name: string,
    render: (p: Parameter) => React.ReactNode
}[] = [];
for (var type in typeIds) {
    typeIdList.push({
        type,
        ...typeIds[type]
    });
}

function ParameterForm({ parameter, onRemove }: { parameter: Parameter, onRemove: (p: Parameter)=>void }) {
    var [i, si] = useState(1);

    const x = parameter;
    return <Box display="flex" gap={1} mb={2} alignItems="center">
        <TextField label="Имя" value={x?.Name} onChange={e => {
            x.Name = e.target.value;
            si(i + 1);
        }} fullWidth />

        <FormControl fullWidth>
            <InputLabel id="Тип">Тип данных *</InputLabel>
            <Select key="Type" labelId="Type" value={x.Type} label="Тип данных" required onChange={e => {
                x.Type = e.target.value as SimpleType;
                si(i + 1);
            }}>
                {typeIdList?.map(x => <MenuItem value={x.type}>{x.name}</MenuItem>)}
            </Select>
        </FormControl>

        {typeIds[x.Type] && typeIds[x.Type].render(x)}

        <IconButton color="error" size="small" onClick={e => onRemove(parameter)}><HorizontalRuleRounded /></IconButton>
    </Box>;
}

export function ParametersForm({ parameters }: { parameters: Parameter[] }) {
    const [parameterKey, setParameterKey] = useState(0);

    return <>
        <Typography variant="h6">
            Параметры ({parameters.length})
            <Button endIcon={<AddRounded />}
                color="success"
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={e => {
                    parameters.push({
                        Type: 'String',
                        Name: 'Новый параметр ' + ((parameters?.length || 0) + 1)
                    });
                    setParameterKey(parameterKey + 1);
                }}>
                Добавить
            </Button>
        </Typography>

        {
            parameters?.map((x, i) => <ParameterForm key={parameterKey + '_' + i} parameter={x} onRemove={p => {
                parameters.splice(parameters.indexOf(p), 1);
                setParameterKey(parameterKey + 1);
            }} />)
        }
    </>;
}