import { CloseRounded, HorizontalRuleRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import Proxy from '../model/Proxy';
import { ResponseFormat } from '../model/Hooks';

export function deleteEntity(typeId: string, id: number, responseFormat?: ResponseFormat) {
    return Proxy.post(typeId + (responseFormat === 'controller' ? '/' : '') + 'Delete', id).then(x => x, x => alert('Ошибка удаления'));
}

export function RemoveDialog({ title, typeId, id, onClose, responseFormat }: { title?: React.ReactNode, typeId: string, id?: any, onClose: () => any, responseFormat?: ResponseFormat }) {
    return <Dialog open={true} className="remove-dialog"
        PaperProps={{
            component: 'form',
            sx: {
                minWidth: 400
            },
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                event.stopPropagation();
                deleteEntity(typeId, id, responseFormat).then(onClose);
            }
        }}
    >
        <DialogTitle>Вы действительно хотите произвести удаление?</DialogTitle>
        <DialogActions disableSpacing sx={{ p: 2, gap: 1 }}>
            <Button startIcon={<HorizontalRuleRounded />} type="submit" color="error">Удалить</Button>
            <Button startIcon={<CloseRounded />} onClick={onClose} color="warning">Отмена</Button>
        </DialogActions>
    </Dialog>;
}