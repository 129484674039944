import { ConnectionType } from "./Enums";

export const connectionTypeTitles: { [key in ConnectionType]: string } = {
	[ConnectionType.Url]: 'CURL',

	[ConnectionType.DB]: 'DB',
	[ConnectionType.Postgres]: 'Postgres',
	[ConnectionType.MsSql]: 'MsSql',
	[ConnectionType.Oracle]: 'Oracle',
	[ConnectionType.MySql]: 'MySql',

	[ConnectionType.RTA]: 'RTAnalytics. SQL-запрос',
	[ConnectionType.GoogleSpreadSheet]: 'GoogleSpreadSheet'
};