import DataView from '../../components/DataView';
import { Role } from '../../model/Types';
import RoleForm from './Form';
import RolesTable from './Table';

export default function RoleView() {
    return <DataView<string, Role>
        table={(onSelectRow) => <RolesTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <RoleForm id={id === 'new' ? '' : id} apiRef={apiRef} />}
    />;
}