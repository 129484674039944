import { ConnectionType, TemplateType } from "./Enums"
import { MetaField } from "./meta/Field"
import StorageInfo from "./storages/StorageInfo"

export interface IEntity<TKey = number> {
    Id: TKey
}

export interface IHierarchicalEntity extends IEntity {
    ParentId?: number

    // dto
    Children: IHierarchicalEntity[]
}

export interface ISuggest<TValue = string, TId = number, TObj = any> {
    Id: TId,
    Value: TValue,
    Obj?: TObj
}

export interface Exception {
    message?: string
    stackTrace?: string
    success?: boolean
    type?: string
}

export class BaseEntity<TKey = number> implements IEntity<TKey> {
    Id!: TKey
    $type?: string
}

type NamedEntity = {
    Name: string
    GroupId?: number
} & BaseEntity

export class IdName extends BaseEntity {
    Name!: string
};

export class BaseHierarchicalEntity extends BaseEntity implements IHierarchicalEntity {
    ParentId?: number

    // dto
    Children!: IHierarchicalEntity[]
}

export type TenantEntity = {
    TenantId: number
} & BaseEntity;

export class Tenant extends BaseEntity {
    Name!: string
    Color?: string
    OwnerId?: number
};

export type User = {
    UserName: string
    Name?: string
    Password: string
    IsAuthenticated: boolean
    Tenants: Tenant[]
    Roles: string[]
    Permissions?: string[]
} & BaseEntity<string>;

export type Role = {
    Name: string
    PermissionKeys: string[]
} & BaseEntity<string>;

export type PermissionGroup = {
    Key?: string
    Name?: string
    Children?: PermissionGroup[]
};

export type Settings = {
    DbConnectionString: string
    HttpRoot: string
    HttpsRoot: string

    DefaultTheme: string
    AllowChangeTheme: boolean

    AuthExpireTime?: number
} & BaseEntity;

export type License = {
    Serial: string,
    MachineKey: string,
    DateExpires: Date,
    ObjectsCountLimit: { [typeId: string]: number | undefined },
    Products?: string[],
    SchedulerLimitType: number
} & BaseEntity

export type ParameterValues = {
    Number?: number
    String?: string
    Date?: Date
    Boolean?: boolean
}
export type SimpleType = keyof ParameterValues;
export type Parameter = {
    Name: string;
    Title?: string;
    Type: SimpleType;
} & ParameterValues;

export type SqlDataSource = {
    ConnectionId?: number
    DsConnectionId?: string
    SqlText: string
    Parameters: Parameter[]
    SqlDataSourceId?: number
    Shared?: boolean
    KeyField?: string
    NameField?: string
} & NamedEntity;

export type Group = {

} & NamedEntity

export class Connection extends BaseEntity {
    _config?: any

    Name!: string
    Type!: ConnectionType
    ConnectionString?: string
    Shared!: boolean

    public get Config() {
        return this._config;
    }

    public set Config(c: any) {
        if (typeof c === typeof '') {
            try {
                c = JSON.parse(c);
            } catch (e) {
                console.log(e);
                c = {};
            }
        }

        this._config = c;
    }
};

export type ReactDashboardArchive = {
    Guid: string
    Uploaded: Date
    FileName: string
    Parameters: Parameter[]
    Type: TemplateType
} & NamedEntity;

export type ReactDashboard = {
    Guid: string
    Shared: boolean
    TemplateId: number
    Endpoints: string[]
    Parameters: Parameter[]
    DataSources: SqlDataSource[]
} & NamedEntity;

export type DashboardStatItem = {
    Id: number
    Name: string
    Count: number
}

export type DashboardStatistics = {
    Sources: number
    Users: number
    Devs: number
    ReactDashboardArchives: number
    ReactDashboards: number
    Stat: DashboardStatItem[]
};

export type MetaKey = {
};

export type Metadata = {
    TypeId: string
    StorageInfo: StorageInfo
    AutoGeneratedFromClass: boolean,
    Annotations: [],
    FacePropertyName: string,
    Internal: boolean
    Fields: MetaField[]
    Keys: [],
    RowCount?: number,
    Parameters: MetaKey[],
    Description: string,
} & NamedEntity;

/* ETL */
export interface IStep {
    $type: string
    Name: string
    X: number
    Y: number
    [field: string]: any
}

export type ETLLink = {
    FromStep: number
    ToStep: number
    FromOutput: string
    ToInput: string
}

export type ETLProcess = {
    Steps: IStep[]
    Links: ETLLink[]
} & NamedEntity;