import DataView from '../../components/DataView';
import { useRoute } from '../../model/Hooks';
import { Metadata } from '../../model/Types';
import MetadataForm from './Form';
import MetadataTable from './Table';

export default function MetadataView() {
    const [route] = useRoute();

    return <DataView<number, Metadata>
        table={(onSelectRow) => <MetadataTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <MetadataForm id={id} onSaved={e => route.setState('id', e.Id)} onDeleted={e => route.setState([['id']])} apiRef={apiRef} />}
    />;
}