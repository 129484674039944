import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material';
import { TableColumn } from '../../table/DataTable';
import { FieldProps } from './FieldProps';
import dayjs from 'dayjs';
import CompositeField from '../../../model/meta/CompositeField';
import { tableFormTypes } from '.';

export function defaultColumn<T>(p: FieldProps): TableColumn<T>{
    return {
        id: p.field.Code as keyof T,
        label: p.field.Name || p.field.Code
    }
}

export function booleanColumn<T>(p: FieldProps): TableColumn<T> {
    const col = defaultColumn<T>(p);
    col.renderer = x => {
        if (x) {
            switch (x[p.field.Code as keyof T]) {
                case true: return <CheckBoxOutlined fontSize="small" />;
                case false: return <CheckBoxOutlineBlankOutlined fontSize="small" />;
                default: return <IndeterminateCheckBoxOutlined fontSize="small" />;
            }
        }
    };

    return col;
}

export function dateColumn<T>(p: FieldProps): TableColumn<T> {
    const col = defaultColumn<T>(p);
    col.renderer = x => {
        const v = x[p.field.Code as keyof T] as Date;
        return v ? dayjs(v).format('DD.MM.YYYY') : null;
    };
    return col;
}

export function dateTimeColumn<T>(p: FieldProps): TableColumn<T> {
    const col = defaultColumn<T>(p);
    col.renderer = x => {
        const v = x[p.field.Code as keyof T] as Date;
        return v ? dayjs(v).format('HH:mm:ss DD.MM.YYYY') : null;
    };
    return col;
}

export function compositeColumn<T>(p: FieldProps): TableColumn<T> {
    const col = defaultColumn<T>(p);

    col.columns = (p.field as CompositeField).Fields.map(x => (tableFormTypes[x['$type'].split('.').filter((_, i) => i).join('.')] || defaultColumn)({
        field: x,
        path: p.path ? [...p.path, p] : [p],
        meta: p.meta
    }));

    return col;
}

export function tableColumn<T>(p: FieldProps): TableColumn<T> {
    const col = defaultColumn<T>(p);
    col.renderer = x => {
        const v = x[p.field.Code as keyof T] as object[];
        return v ? <>[{v.length}]</> : null;
    };
    return col;
}