import Observer from "../Observer";
import { Parameter } from "../Types";
import { fieldFromParameter } from "../fields";
import DataField from "../fields/DataField";

export interface DSConfig {
    Parameters: Parameter[]
};

const events = {
    dataloaded: 'dataloaded',
    dataloading: 'dataloading'
}

export default abstract class Datasource<TConfig extends DSConfig, TData = any> extends Observer {
    protected _config: TConfig;
    protected _params!: DataField[]

    protected _data?: TData[]

    constructor(config: TConfig) {
        super();

        this._config = config;

        this._params = config.Parameters.map(x => new DataField(fieldFromParameter(x)));
    }

    abstract load(): Promise<TData[]>

    onDataBeforeLoad(fn: (d: TData[]) => void) {
        return this.listen(events.dataloading, fn);
    }

    onDataLoad(fn: (d: TData[]) => void) {
        return this.listen(events.dataloaded, fn);
    }

    setParamValue(name: string, value: any) {
        const df = this._params.find(x => x.name === name)!;
        if (df.value !== value) {
            df.value = value;
        }
    }

    public get data() {
        return this._data;
    }

    public set data(d: TData[] | undefined) {
        this._data = d;
    }
}