import { ExitToApp } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MouseEventHandler } from 'react';
import { LogoB } from '../images/logo';
import { logout } from '../model/Identity';
import { menu, setMenuItem, useAppRoute } from './AppRoute';

export const drawerWidth: number = 240;

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            backgroundColor: '#282828',
            color: '#fff',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface Props extends DrawerProps {
    onToggle: MouseEventHandler<HTMLButtonElement>
}

export default function Drawer({ onToggle, ...props }: Props) {
    const [, , , currentItem] = useAppRoute(menu);

    return <StyledDrawer {...props}>
        <Toolbar sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
        }}>
            <Typography variant="h5" alignItems="center" alignContent="center" justifyContent="center" display="flex" color="white">
                <LogoB style={{ height: '2.25em', width: '4em', marginRight: '.5em' }} />
            </Typography>
            <IconButton onClick={onToggle} color="inherit">
                <ChevronLeftIcon />
            </IconButton>
        </Toolbar>

        <List component="nav">
            {menu.filter(x => !x?.filter || x.filter())
                .map((x, i) => x?.path !== undefined ?
                    <ListItem key={x.title?.toString()}
                        id={x.id}
                        disablePadding
                        sx={{ display: 'block' }}
                        className={currentItem === x ? 'active' : undefined}
                        onClick={() => { setMenuItem(x); }}>
                        <ListItemButton sx={styles.listItemButton(!!props.open)}>
                            <ListItemIcon sx={styles.listItemIcon(!!props.open, currentItem === x)}>{x?.icon}</ListItemIcon>
                            <ListItemText primary={x?.title} primaryTypographyProps={{ sx: styles.listItemText(!!props.open, x, currentItem == x) }} />
                        </ListItemButton>
                    </ListItem> :
                    x?.title ? <ListItem id={x.id} key={x.title?.toString()}>
                        <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(!!props.open) }} />
                    </ListItem> :
                        <Divider key={i} color="#aaa" />)}
        </List>

        <div style={{ flex: 1 }} />

        <ListItem key="logout" disablePadding onClick={logout} sx={{ flex: 0 }}>
            <ListItemButton sx={styles.listItemButton(!!props.open)}>
                <ListItemIcon sx={styles.listItemIcon(!!props.open)}><ExitToApp /></ListItemIcon>
                <ListItemText primary="Выход" primaryTypographyProps={{ sx: styles.listItemText(!!props.open) }} />
            </ListItemButton>
        </ListItem>
    </StyledDrawer>;
}

const styles: { [name: string]: (open: boolean, ...props: any) => React.CSSProperties } = {
    listItemIcon: (open: boolean, current: boolean) => {
        return {
            transition: 'all 225ms',
            minWidth: 0,
            mr: open ? 1.5 : 0,
            opacity: current ? 1 : .8,
            color: current ? 'error.main' : '#fff',
            justifyContent: 'center',
        }
    },
    listItemButton: (open: boolean) => {
        return {
            minHeight: 24,
            justifyContent: open ? 'initial' : 'center',
            py: .6,
        }
    },
    listItemText: (open: boolean, item: any, current: boolean) => {
        return {
            transition: 'all 225ms',
            opacity: open ? current ? 1 : .8 : 0,
            maxWidth: open ? 'auto' : 0,
            color: current ? 'error.main' : item?.color,
            fontSize: open ? '1.3rem' : 0,
            fontWeight: current ? undefined : 300
        }
    },
    listItemSecondary: (open: boolean) => {
        return {
            opacity: open ? .7 : 0,
            maxWidth: open ? 'auto' : 0,
            color: 'secondary',
            fontWeight: 300,
            fontSize: '0.9rem'
        }
    }
}