import { AddRounded, CloseRounded } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, MenuItem, Select, SelectProps } from "@mui/material";
import { useState } from "react";
import DataView from "../../components/DataView";
import { TableApi } from "../../components/table/DataTable";
import { useRemoteData, useRoute } from '../../model/Hooks';
import { SqlDataSource } from "../../model/Types";
import DataSourceForm from './Form';
import DataSourcesTable from './Table';

export function DataSourceCombo(props: SelectProps) {
    const [rows] = useRemoteData<SqlDataSource[]>('SqlDataSource/SimpleList?showAll=true', null, 'controller');
    return <Select key={rows?.length} labelId="templateId" label="Источник данных" name="templateId" required {...props}>
        {rows?.map(x => <MenuItem value={x.Id}>{x.Name}</MenuItem>)}
        <Divider key="divider" />
        <MenuItem><CloseRounded /> Очистить</MenuItem>
    </Select>
}

function About() {
    return <Box display="flex" flex={1} alignItems="center" justifyContent="center">
        <Card>
            <CardHeader title="Источники данных" />
            <CardContent>В данном разделе можно настроить способы получения данных из различных источников...</CardContent>
            <CardActions>
                <Button color="success" variant="contained" startIcon={<AddRounded />} sx={{ mx: 'auto' }} {...{ "data-route": "id=new" }}>Добавить Источник данных</Button>
            </CardActions>
        </Card>
    </Box>;
}

export function DataSourcesView({ id }: { id: string }) {
    var [tableApi, setTableApi] = useState<TableApi<SqlDataSource> | null>();


    var [route] = useRoute();
    return <DataView<number, SqlDataSource>
        table={(onSelectRow) => <DataSourcesTable refApi={api => (!tableApi === !api) || setTableApi(api)} onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <DataSourceForm id={id} form={{
            onSaved: e => { tableApi?.reload && tableApi.reload(); route.setState('id', e.Id) },
            onDeleted: () => { tableApi?.reload && tableApi.reload(); route.setState([['id']]) }
        }} apiRef={apiRef} />}
    />;

    return <Box display="flex" flex={1}>
        <Box width={450} display="flex" borderRight={1} borderColor="rgba(0, 0, 0, 0.12)">
            <DataSourcesTable refApi={api => (!tableApi === !api) || setTableApi(api)} />
        </Box>
        {id ?
            <Box flex={1} sx={{ overflow: 'auto' }}>
                <DataSourceForm key={'form' + id} id={parseInt(id) > 0 ? parseInt(id) : 0} form={{ onSaved: () => tableApi?.reload && tableApi.reload() }} />
            </Box> :
            <About />}
    </Box>;
}

export default function DataSourcesRouteView() {
    var [route] = useRoute();

    return <DataSourcesView id={route.get('id')} />;
}