import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useRemoteData } from '../model/Hooks';
import { DashboardStatItem, DashboardStatistics, IdName } from '../model/Types';

import { ArrowForward, Dashboard, Dataset, Group, SnippetFolder } from '@mui/icons-material';
import { Card, Link, Palette, Table, TableBody, TableCell, TableContainer, TableRow, Toolbar, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ReactNode, useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import route from '../Router';
import { hasAccess, isSuper } from '../model/Identity';
import { EmdTheme } from '../theme';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

interface AppWidgetSummaryProps {
    page?: string
    color?: keyof Palette,
    icon?: ReactNode,
    title: string,
    total?: number,
    left?: IdName,
    right?: IdName,
    sx?: any
};

function AppWidgetNum({ label, value, page }: { label: string, value?: number, page?:string }) {
    return <Box flex={1}>
        <Typography variant="h3">{value}</Typography>
        <Typography variant="subtitle1" display="flex" alignItems="center" justifyContent="center" sx={{ opacity: 0.72 }}>
            {label} {page && <ArrowForward fontSize='small' />}
        </Typography>
    </Box>;
}

function AppWidgetSummary({ title, left, right, total, icon, page, color = 'primary', sx, ...other }: AppWidgetSummaryProps) {
    const card = <Card
        sx={{
            py: 5,
            textAlign: 'center',
            color: (theme) => (theme.palette[color] as any).darker,
            bgcolor: (theme) => (theme.palette[color] as any).lighter
        }}
        {...other}
    >
        <StyledIcon
            sx={{
                color: (theme) => (theme.palette[color] as any).dark,
                backgroundImage: (theme) =>
                    `linear-gradient(135deg, ${alpha((theme.palette[color] as any).dark, 0)} 0%, ${alpha(
                        (theme.palette[color] as any).dark,
                        0.24
                    )} 100%)`,
            }}
        >
            {icon}
        </StyledIcon>

        <Box display="flex">
            {left && <AppWidgetNum label={left.Name} value={left.Id} page={page} />}
            <AppWidgetNum label={title} value={total} page={page} />
            {right && <AppWidgetNum label={right.Name} value={right.Id} page={page} />}
        </Box>
    </Card>;

    return page ? <Link sx={{ textDecoration: 'inherit', cursor: 'pointer' }} data-route={'page=' + page}>
        {card}
    </Link> : card;
}

function StatComp({ stat }: { stat?: DashboardStatItem[] }) {
    const theme = useTheme();

    const primaryAxis = useMemo<AxisOptions<DashboardStatItem>>(
        () => ({
            position: "left",
            getValue: (datum) => datum.Name,
        }),
        []);

    const secondaryAxes = useMemo<AxisOptions<DashboardStatItem>[]>(
        () => [{
            position: "bottom",
            min: 0,
            getValue: (datum) => datum.Count,
        }],
        []);

    if (!stat?.length) {
        return <></>;
    }

    return <Grid item xs={12} sm={6} md={8}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%', width: '100%', overflow: 'hidden' }}>
            <div style={{ flex: 1 }}>
                <Chart options={{
                    data: [{
                        label: 'Открытий',
                        data: stat
                    }],
                    defaultColors: [theme.palette.primary.main],
                    primaryAxis,
                    secondaryAxes,
                    onClickDatum: (d) => d?.originalDatum.Id && route.setState([
                        ['page', 'dashboards'],
                        ['id', d.originalDatum.Id]
                    ])
                }}
                />
            </div>
        </Paper>
    </Grid>;
}

function HomeDefault() {
    const [data] = useRemoteData<DashboardStatistics>('statistics/dashboard', undefined, 'controller');

    return <Grid container spacing={3} py={3} px={6}>
        <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
                title="Пользователи"
                total={data?.Users}
                left={data?.Devs ? { Id: data.Devs, Name: 'Разработчики' } : undefined}
                icon={<Group />}
                color="info"
                page={isSuper() ? 'users' : undefined} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Шаблоны" total={data?.ReactDashboardArchives} icon={<SnippetFolder />} color="warning"
                page={hasAccess('ReactDashboardArchive') ? 'templates' : undefined} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Дашборды" total={data?.ReactDashboards} icon={<Dashboard />} color="error"
                page={hasAccess('ReactDashboard') ? 'dashboards' : undefined} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Источники данных" total={data?.Sources} icon={<Dataset />} color="secondary"
                page={hasAccess('SqlDataSource') ? 'sources' : undefined} />
        </Grid>
        <StatComp stat={data?.Stat} />
    </Grid>;
}

function HomeMPT() {
    const [data] = useRemoteData<DashboardStatistics>('statistics/dashboard', undefined, 'controller');

    const tables = [{
        title: 'Общая информация',
        data: [
            ['Пользователи', data?.Users],
            ['Источники данных', data?.Sources],
            ['Шаблоны', data?.ReactDashboardArchives],
            ['Дашборды', data?.ReactDashboards],
        ]
    }, {
        title: 'Статистика открытия дашбордов',
        data: data?.Stat?.map(x => [x.Name, x.Count])
    }];

    return <Box className="data-view">
        {tables.map(t => <Box display="flex" flexDirection="row" className="datatable-wrapper">
            <Paper>
                <Toolbar>
                    <Typography variant="h6" className="title">{t.title}</Typography>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {t.data?.map(x => <TableRow>
                                <TableCell style={{ paddingLeft: 0 }}>{x[0]}</TableCell>
                                <TableCell sx={{ px: '6em' }} >{x[1]}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>)}
    </Box>;
}

export default function Home() {
    const theme = useTheme<EmdTheme>();
    if (theme.id === 'mpt') {
        return <HomeMPT />;
    } else {
        return <HomeDefault />;
    }
}