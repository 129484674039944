import { FullscreenRounded, PlayArrow } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItemButton, ListItemText, styled } from '@mui/material';
import { Background, Controls, Edge, Handle, Node, NodePositionChange, OnSelectionChangeParams, Position, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { HTMLProps, memo, useRef, useState } from 'react';
import EntityForm, { Entity } from '../../components/entity/EntityForm';
import DataTextField from '../../components/fields/DataTextField';
import { DataTabForm, EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import Proxy from '../../model/Proxy';
import { ETLLink, ETLProcess, IStep } from '../../model/Types';
import { getETLStepByTypeId, useETLSteps } from '../../model/etl/ETLStepStore';
import { deleteMeta } from '../../model/meta/MetaStore';
import fieldOverrides from './fieldOverrides';

const proxy: FormProxy<ETLProcess> = {
    get: id => 'ETLProcessGet?id=' + (id > 0 ? id : 0),
    save: e => 'ETLProcessSave',
    delete: e => 'ETLProcessDelete'
}

const StepNode = styled((props: HTMLProps<HTMLDivElement> & { selected: boolean }) => <div {...props} />)(({ theme, selected }) => ({
    padding: '10px 20px',
    borderRadius: 5,
    background: '#fff',
    color: '#000',
    border: '1px solid ' + (selected ? theme.palette.primary.main : theme.palette.divider),
    '& .react - flow__handle': {
        background: theme.palette.primary.main,
        width: 8,
        height: 10,
        borderRadius: 3
    }
}));

const nodeTypes = {
    rowrow: memo(({ data, selected }: { data: IStep, selected: boolean }) => {
        var meta = getETLStepByTypeId(data['$type']);
        return <StepNode selected={selected}>
            {meta?.Inputs.map((x, i) => <Handle
                type="target"
                id={'i_' +x.Name}
                position={Position.Top}
                style={{ background: '#555', left: ((i + 1) * 100 / (meta!.Inputs.length + 1)) + '%' }}
                isConnectable={true}
            />)}
            
            <div>{data?.Name}</div>

            {meta?.Outputs.map((x, i) => <Handle
                type="source"
                position={Position.Bottom}
                style={{ background: '#555', left: ((i + 1) * 100 / (meta!.Outputs.length + 1)) + '%' }}
                id={'o_' + x.Name}
                isConnectable={true}
            />)}
        </StepNode>;
    }) as any
};

function stepToNode(x: IStep, i: number) {
    return {
        id: i.toString(),
        position: { x: x.X, y: x.Y },
        type: 'rowrow',
        data: x
    } as Node;
};

function linkToEdge(x: ETLLink) {
    return {
        id: `link_${x.FromStep}_${x.FromOutput}_${x.ToStep}_${x.ToInput}`,
        source: x.FromStep.toString(),
        sourceHandle: 'o_' + x.FromOutput,
        target: x.ToStep.toString(),
        targetHandle: 'i_' + x.ToInput
    } as Edge;
};

function ETLProcessFlow({ steps, links }: { steps: IStep[], links: ETLLink[] }) {
    const stepMetas = useETLSteps();
    const [selected, setSelected] = useState<number>();
    const stepFormApi = useRef<EntityApi<Entity, number>>();

    const [nodes, setNodes, onNodesChange] = useNodesState(steps.map(stepToNode));
    const [edges, setEdges, onEdgesChange] = useEdgesState(links.map(linkToEdge));

    if (!stepMetas) {
        return <></>;
    }

    const selectedStep = selected || (selected === 0) ?steps[selected] : null;
    return <>
        <List disablePadding>
            {stepMetas?.map(x => <ListItemButton key={x.TypeId} onClick={() => {
                const step = {
                    $type: x.TypeId,
                    Name: x.Name,
                    X: 0,
                    Y: (-steps.map(x => -x.Y).sort()[0] + 75) || 0
                };
                steps.push(step);

                setNodes(nodes.concat(stepToNode(step, steps.length - 1)));
            }}>
                <ListItemText primary={x.Name} />
            </ListItemButton>)}
        </List>
        <div style={{ flex: 1 }}>
            <ReactFlow fitView
                style={{ flex: 1 }}
                onConnect={x => {
                    const link = {
                        FromOutput: x.sourceHandle?.substring(2),
                        ToInput: x.targetHandle?.substring(2),
                        FromStep: nodes.findIndex(n => n.id === x.source),
                        ToStep: nodes.findIndex(n => n.id === x.target)
                    } as ETLLink;

                    if (!links.find(x => x.FromOutput === link.FromOutput &&
                        x.ToInput === link.ToInput &&
                        x.FromStep === link.FromStep &&
                        x.ToStep === link.ToStep)) {
                        links.push(link);

                        setEdges(edges.concat(linkToEdge(link)));
                    }
                }}
                onDelete={x => {
                    debugger;
                }}
                onSelectionChange={({ nodes }: OnSelectionChangeParams) => {
                    if (stepFormApi.current) {
                        stepFormApi.current.save();
                    }

                    setSelected(nodes[0] ? parseInt(nodes[0].id) : undefined);
                }}
                nodeTypes={nodeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={c => {
                    c.forEach(x => {
                        switch (x.type) {
                            case 'position':
                                if (x.position) {
                                    let step = steps[parseInt(x.id)];
                                    step.X = x.position?.x;
                                    step.Y = x.position?.y;
                                }
                                break;
                        }
                    })
                    onNodesChange(c);
                }}
                onEdgesChange={onEdgesChange}>
                <Background />
                <Controls />
            </ReactFlow>
        </div>
        {selectedStep ?
            <div style={{ flex: 1, overflow: 'auto' }}>
                <EntityForm key={'form_' + selected}
                    typeId={selectedStep.$type}
                    id={0}
                    data={selectedStep as any}
                    apiRef={api => stepFormApi.current = api}
                    onSubmit={e => { return null; }}
                    responseFormat="local"
                    allowSave={false}
                    overrides={fieldOverrides.map(x => x(selectedStep))}
                />
            </div> :
            null}
    </>;
}

export default function ETLProcessForm({ apiRef, ...props }: IdFormProps<ETLProcess> & { apiRef?: (api: EntityApi<ETLProcess>) => any }) {
    const [steps, setSteps] = useState<IStep[]>([]);
    const [links, setLinks] = useState<ETLLink[]>([]);
    const [cnt, setCnt] = useState(0);

    const [fullScreen, setFullScreen] = useState(false);

    function onGet(x: ETLProcess) {
        const steps = (x.Steps as any)?.$values || x.Steps || [];
        const links = (x.Links as any)?.$values || x.Links || [];
        setSteps(steps);
        setLinks(links);
        setCnt(steps.length + links.length);
        return x;
    }

    function onSubmit(entity: ETLProcess) {
        entity.Steps = steps;
        entity.Links = links;
        return entity;
    }

    return <DataTabForm<ETLProcess> {...props}
        sx={{
            body: {
                p: 0
            },
            form: fullScreen ? styles.full : styles.notFull
        }}
        proxy={proxy}
        onSubmit={onSubmit}
        onDeleted={x => deleteMeta(x.Id)}
        onGet={onGet}
        toolbar={{
            actionsSx: {
                flex: 1,
                mx: 0
            },
            actions: (tp, entity) => <>
                <IconButton size="small" onClick={e =>  setFullScreen(!fullScreen)}><FullscreenRounded /></IconButton>
                <div style={{ flex: 1 }} />
                <Button startIcon={<PlayArrow />} onClick={e => Proxy.post("/api/ETLRun", entity?.Id)}>Запустить</Button>
                <div style={{ flex: 1 }} />
            </>
        }}
        tabs={[{ text: 'Основное' }, { text: 'Процесс' }]}>
        {[(entity, api) => {
            apiRef && apiRef(api);
            return <Box sx={{ px: 3, display: 'flex', flexDirection: 'column' }}>
                <DataTextField entity={entity} label="Наименование" field="Name" />
            </Box>;
        }, (entity, api) => {
            apiRef && apiRef(api);
            return <div style={{ height: 500, flex: 1, width: '100%', display: 'flex' }}>
                <ETLProcessFlow key={cnt} steps={steps} links={links} />
            </div>
        }]}
    </DataTabForm>
}

const styles = {
    full: {
        position: 'absolute',
        width: 'calc(100% - 2.5rem) !important',
        height: 'calc(100% - 2.5rem) !important'
    },
    notFull: undefined
}