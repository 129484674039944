import { CheckRounded, CloseRounded, Folder } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { useMemo, useState } from 'react';
import route from '../Router';
import { ResponseFormat, useRemoteData } from '../model/Hooks';
import Proxy from '../model/Proxy';
import { Group, IEntity, IdName } from '../model/Types';
import { IAppMenuItem } from '../nav/AppRoute';

type TreeNode<T> = T & {
    children: TreeNode<T>[]
    parent: TreeNode<T>
} & IEntity;

function TreeNodeItem<T>({ node, itemId, label }: { node: TreeNode<T>, itemId?: keyof TreeNode<T>, label: keyof TreeNode<T> }) {
    return <TreeItem itemId={((itemId && node[itemId]) || node.Id)?.toString()} label={node[label]?.toString()}>
        {node.children?.map(x => <TreeNodeItem node={x} itemId={itemId} label={label} />)}
    </TreeItem>;
}

export function deleteGroup(id:number) {
    return Proxy.post('Group/Delete', id).then(x => {
        var path = route.get<IdName[]>('path');
        route.setState('path', path.slice(0, path.length - 1));
    }, x => alert('Ошибка удаления'));
}

export interface OpenProps {
    id: number,
    name?: string,
    groupId?: number
}

interface LocationDialogProps<TKey> {
    title?: React.ReactNode,
    typeId: string,
    id?: TKey,
    open: OpenProps,
    onClose: () => any,
    responseFormat?: ResponseFormat
}

export function LocationDialog<TKey = any>({ title, typeId, id, open, onClose, responseFormat }: LocationDialogProps<TKey>) {
    const [data] = useRemoteData<Group[]>('Group/FullList', null, responseFormat);
    const [treeData, setTreeData] = useState<TreeNode<Group>[]>();

    useMemo(() => {
        if (data) {
            const treeData: TreeNode<Group>[] = data
                .filter(x => !x.GroupId && x.Name === typeId)
                .map(x => { return { ...x, Name: title } as TreeNode<Group> });
            const watching: TreeNode<Group>[] = [...treeData];

            const path = [];
            while (watching.length) {
                let w = watching.pop()!;
                w.children = data.filter(x => x.GroupId == w.Id).map(x => x as TreeNode<Group>);
                w.children.forEach(x => {
                    x.parent = w;
                    watching.push(x);
                });

                if (w.Id == open?.groupId) {
                    while (w.parent) {
                        path.push(w);
                        w = w.parent;
                    }
                }
            }

            setTreeData(treeData);
        }
    }, [data]);

    return <Dialog open={!!open}
        className="location-dialog"
        PaperProps={{
            component: 'form',
            sx: {
                minWidth: 400
            },
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                event.stopPropagation();
                if (open) {
                    var groupId = open.groupId;
                    Proxy.post(id ? typeId + (responseFormat === 'controller' ? '/' : '') + 'Move' : 'Group/Save', {
                        id: open.id,
                        name: new FormData(event.currentTarget).get('name'),
                        groupId
                    }).then(x => {
                        onClose();
                        var path: IdName[] = [];
                        if (!id) {
                            path.push({ Id: x.id, Name: x.name });
                        }

                        while (groupId) {
                            let group = data?.find(x => x.Id == groupId);
                            groupId = group?.GroupId;
                            if (groupId && group) {
                                path.push({ Id: group.Id, Name: group.Name });
                            }
                        }

                        route.setState('path', path.reverse());
                    }, x => {
                        debugger;
                    });
                }
            },
        }}
    >
        <DialogTitle>{open?.id ? 'Расположение' : 'Каталог'}</DialogTitle>
        <DialogContent>
            {open ? <>
                {id ? null :
                    <TextField autoFocus required fullWidth
                        margin="dense"
                        name="name"
                        label="Наименование"
                        defaultValue={open.name}
                        onChange={e => open.name = e.target.value} />
                }
                <SimpleTreeView
                    selectedItems={open?.groupId?.toString()}
                    onItemSelectionToggle={(e, itemId, sel) => sel && open && (open.groupId = parseInt(itemId))}>
                    {treeData?.map(x => <TreeNodeItem node={x} label="Name" />)}
                </SimpleTreeView>
            </> : null}
        </DialogContent>
        <DialogActions disableSpacing sx={{ p: 2, gap: 1 }}>
            {id ? null : <Button onClick={() => deleteGroup(open.id).then(onClose)} color="error">Удалить</Button>}
            <Button startIcon={<CheckRounded />} type="submit" disabled={!open} sx={{ ml: 'auto' }} color="success">Сохранить</Button>
            <Button startIcon={<CloseRounded />} onClick={onClose} color="warning">Отмена</Button>
        </DialogActions>
    </Dialog>;
}

export default function ChangeLocation({ menuItem, id }: { menuItem: IAppMenuItem, id: any }) {
    const [open, setOpen] = useState<OpenProps>();

    return <>
        <IconButton key="change-group"
            sx={{ ml: 1 }}
            title="Расположение"
            onClick={e => {
                e.preventDefault();
                const id = route.get<number>('id');
                const path = [...route.get<IdName[]>('path')]?.reverse();

                setOpen({
                    id: id || path[0].Id,
                    name: id ? '' : path[0].Name,
                    groupId: path[id ? 0 : 1]?.Id
                });
            }}><Folder fontSize="small" /></IconButton>

        {open ? <LocationDialog typeId={menuItem.typeId!} title={menuItem.title} id={id} open={open} onClose={() => setOpen(undefined)} /> : null}
    </>;
}