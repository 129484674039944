import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { connectionTypeTitles } from '../../model/EnumTitles';
import { Connection } from '../../model/Types';
import connectionsIcon from '../../theme/mpt/connectionsIcon';

const headCells: TableColumn<Connection>[] = [
    {
        id: 'Name',
        label: 'Наименование',
    },
    {
        id: 'Type',
        label: 'Тип',
        renderer: x => connectionTypeTitles[x.Type] || x.Type
    }
];

export default function ConnectionsTable(props: TableProps<Connection>) {
    return <DataTable toolbar={{
        title: 'Соединения',
        icon: connectionsIcon
    }} typeId="Connection"
        columns={headCells}
        url="Connection/List"
        responseFormat="controller"
        groupName="Connection" {...props} />;
}