import { OpenInNew } from '@mui/icons-material';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { ParametersForm } from '../../components/ParametersForm';
import { HtmlJsChips } from '../../components/UrlField';
import DataForm, { EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import { TemplateType } from '../../model/Enums';
import { resolveHub } from '../../model/Hub';
import Proxy from '../../model/Proxy';
import { Parameter, ReactDashboardArchive } from '../../model/Types';

const proxy: FormProxy<ReactDashboardArchive> = {
    get: id => 'ReactDashboardArchive/Get/' + id,
    save: e => 'ReactDashboardArchive/Save',
    delete: e => 'ReactDashboardArchive/Delete'
}

const types = {
    [TemplateType.Archive]: 'Архив',
    [TemplateType.CodeProject]: 'Проект'
};

const editorRoot = process.env.NODE_ENV == 'development' ? document.location.protocol + '//' + document.location.hostname + ':4000' : '';

export default function TemplateForm({ apiRef, ...props }: IdFormProps<ReactDashboardArchive> & { apiRef?: (api: EntityApi<ReactDashboardArchive>) => any }) {
    const [parameters, setParameters] = useState<Parameter[]>([]);

    function onGet(x: ReactDashboardArchive) {
        setParameters(x.Parameters || []);
        return x;
    }

    function onSubmit(entity: ReactDashboardArchive) {
        entity.Parameters = parameters;
        return entity;
    }

    function openEditor(entity: ReactDashboardArchive) {
        return;
/**
        Proxy.get('CodeEditor/Start/' + entity.Id).then((token: string) => resolveHub(entity.Id, token).onSetPort(port => {
            window.open(document.location.hostname + ':' + port + '?templateId=' + entity.Guid, 'Редактор', '_blank')?.focus();
        }));
/**/
    }

    return <DataForm<ReactDashboardArchive> {...props} responseFormat="controller" proxy={proxy} onSubmit={onSubmit} onGet={onGet}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField label="Наименование" name="name" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth />
                </Box>

                {entity.Type == TemplateType.Archive ? <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
                        <TextField label="Файл" name="fileName" value={entity?.FileName} fullWidth />
                        <TextField label="Загружен" name="uploaded" value={moment(entity?.Uploaded).format('LD LT')} sx={{ width: 300 }} />
                    </Box>
                    {entity.Guid ? <HtmlJsChips label="Ссылки" guid={entity.Guid} path="template" /> : null}
                </> : <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
                    <Typography variant="h6">Проект:</Typography>
                    <Button
                        LinkComponent={Link}
                        href={editorRoot + '/editor?templateId=' + entity.Guid}
                        target="_blank"
                        variant="contained"
                        endIcon={<OpenInNew />}
                            disabled={!entity.Guid || entity.Guid == ''}
                            title={entity.Guid ? '' : 'Доступно после создания'}
                        onClick={() => openEditor(entity)}
                    >
                        Открыть редактор
                    </Button>
                </Box>}

                <ParametersForm parameters={parameters} />
            </>;
        }}
    </DataForm>
}