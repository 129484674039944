import { Dashboard, VerifiedUser } from '@mui/icons-material';
import router, { UrlRoute } from "../Router";
import { appIsData, appIsGraph, appName } from '../Startup';
import { useRoute } from '../model/Hooks';
import { getUser, hasAccess, isSuper } from '../model/Identity';
import HomePage from '../pages/Home';
import LicenseForm from '../pages/LicenseForm';
import ConnectionView from '../pages/connections';
import Dashboards from '../pages/dashboards';
import Sources from '../pages/datasources';
import EntityView from '../pages/entity';
import ETLProcessView from '../pages/etl';
import MetadataView from '../pages/metadatas';
import RoleView from '../pages/roles';
import SettingsForm from '../pages/settings';
import Templates from '../pages/templates';
import UserView from '../pages/users';
import UserForm from '../pages/users/Form';
import analyticIcon from '../theme/mpt/analyticIcon';
import connectionsIcon from '../theme/mpt/connectionsIcon';
import dashboardsIcon from '../theme/mpt/dashboardsIcon';
import datasourcesIcon from '../theme/mpt/datasourcesIcon';
import licenseIcon from '../theme/mpt/licenseIcon';
import memoryIcon from '../theme/mpt/memoryIcon';
import rolesIcon from '../theme/mpt/rolesIcon';
import settingsIcon from '../theme/mpt/settingsIcon';
import templatesIcon from '../theme/mpt/templatesIcon';
import userIcon from '../theme/mpt/userIcon';
import { getMetaByTypeId } from '../model/meta/MetaStore';

export interface IAppMenuItem {
    id?: string
    path?: string,
    title?: React.ReactNode,
    tenanted?: boolean,
    icon?: React.ReactNode,
    element?: () => React.ReactNode,
    color?: string
    typeId?: string
    filter?: () => boolean
    breadcrumbs?: () => {
        title: React.ReactNode,
        handler: () => any
    }[]
}

export type IAppMenu = (IAppMenuItem | null)[];
export const menu: IAppMenu = [
    {
        id: 'desktop-menu',
        path: "",
        title: 'Аналитика',
        //icon: <LeaderboardRounded />,
        icon: analyticIcon,
        element: () => <HomePage />,
    },
    {
        id: 'connections-menu',
        path: "connections/*",
        title: 'Соединения',
        //icon: <Link />,
        icon: connectionsIcon,
        element: () => <ConnectionView />,
        typeId: 'Connection',
        filter: () => hasAccess('Connection')
    },
    {
        id: 'sources-menu',
        path: "sources/*",
        title: 'Источники',
        //icon: <Dataset />,
        icon: datasourcesIcon,
        element: () => <Sources />,
        typeId: 'SqlDataSource',
        filter: () => (appName() === 'graph') && hasAccess('SqlDataSource')
    },
    {
        id: 'clients-menu',
        path: "templates/*",
        title: 'Шаблоны',
        tenanted: true,
        //icon: <SnippetFolder />,
        icon: templatesIcon,
        element: () => <Templates />,
        typeId: 'ReactDashboardArchive',
        filter: () => appIsGraph() && hasAccess('ReactDashboardArchive')
    },
    {
        id: 'groups-menu',
        path: "dashboards/*",
        tenanted: true,
        title: 'Дашборды',
        //icon: <DashboardRounded />,
        icon: dashboardsIcon,
        element: () => <Dashboards />,
        typeId: 'ReactDashboard',
        filter: () => appIsGraph() && hasAccess('ReactDashboard')
    },
    {
        id: 'metadata-menu',
        path: "metadata/*",
        title: 'Хранилища',
        //icon: <Dataset />,
        icon: datasourcesIcon,
        element: () => <MetadataView />,
        typeId: 'Metadata',
        filter: () => appIsData() && hasAccess('Metadata')
    },
    {
        id: 'etl-menu',
        path: "etl/*",
        title: 'ETL',
        //icon: <Dataset />,
        icon: memoryIcon,
        element: () => <ETLProcessView />,
        typeId: 'ETLProcess',
        filter: () => appIsData() && hasAccess('ETL')
    },
    {
        id: 'metadata-menu',
        path: "dataview/*",
        title: 'Данные',
        breadcrumbs: () => {
            const typeId = router.get<string>('typeId');
            const meta = getMetaByTypeId(typeId);
            return [{
                title: 'Данные',
                handler: () => router.setPath('/data/metadata/' + meta?.Id)
            }, {
                title: meta?.Name || typeId,
                handler: () => router.setState([['id']])
            }];
        },
        element: () => <EntityView typeId={router.get<string>('typeId')} />,
        filter: () => false
    },
    null,
    {
        title: 'Администрирование',
        icon: <Dashboard />,
        filter: isSuper
    },
    {
        id: 'users-menu',
        path: "users/*",
        tenanted: true,
        title: 'Пользователи',
        icon: userIcon,
        element: () => <UserView />,
        typeId: 'User',
        filter: isSuper
    },
    {
        id: 'roles-menu',
        path: "roles/*",
        tenanted: true,
        title: 'Роли',
        icon: rolesIcon,
        element: () => <RoleView />,
        typeId: 'Role',
        filter: isSuper
    },
    {
        id: 'settings-menu',
        path: "settings/*",
        tenanted: true,
        title: 'Настройки',
        filter: isSuper,
        icon: settingsIcon,
        element: () => <SettingsForm />,
    },
    {
        id: 'license-menu',
        path: "license/*",
        tenanted: true,
        title: 'Лицензия',
        filter: isSuper,
        icon: licenseIcon,
        element: () => <LicenseForm />,
    },
    {
        id: 'current-user',
        path: "users/current",
        tenanted: true,
        title: 'Пользователь',
        filter: () => !!getUser() && !isSuper(),
        icon: <VerifiedUser />,
        element: () => <UserForm />,
    }
];

export const route = menu.filter(x => x);

export function useAppRoute(menu: IAppMenu): [UrlRoute, string, number, IAppMenuItem] {
    var [route, path] = useRoute();
    path = path.substring(route.get('app').length + 1);

    var current = 0;
    menu.reduce((y, x, i) => (x?.path && !path.indexOf(x.path.replace(/\/\*$/, '').replace(/\/:.*$/, ''))) ? current = i : i, 0);
    return [route, path, current, menu[current]!];
}

export function setMenuItem(item: IAppMenuItem) {
    router.setPath(router.get<string>('app') + '/' + getMenuItemPath(item));
}

export function getMenuItemPath(item: IAppMenuItem) {
    return item.path!.replace(/\/\*$/, '').replace(/\/:.*$/, '');
}