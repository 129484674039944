import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { ETLProcess } from '../../model/Types';
import datasourcesIcon from '../../theme/mpt/datasourcesIcon';

const headCells: TableColumn<ETLProcess>[] = [
    {
        id: 'Name',
        label: 'Наименование',
        renderer: x => x.Name
    }
];

export default function MetadataTable(props: TableProps<ETLProcess>) {
    return <DataTable
        toolbar={{
            title: 'ETL процессы',
            icon: <div style={{ zoom: .5, display: 'flex' }} >{datasourcesIcon}</div>
        }}
        columns={headCells}
        url="ETLProcessGetAll"
        typeId="ETLProcess"
        groupName="ETLProcess"
        {...props} />;
}