import { ChevronRight } from '@mui/icons-material';
import { Box, Chip, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import CheckBoxField from '../../components/fields/CheckBoxField';
import DataForm, { EntityApi, FormProxy } from '../../components/form/DataForm';
import { useRemoteData } from '../../model/Hooks';
import Proxy from '../../model/Proxy';
import { PermissionGroup, Role } from '../../model/Types';

const proxy: FormProxy<Role, string> = {
    get: id => 'Role/Get/' + id,
    save: e => 'Role/Save',
    delete: e => 'Role/Delete'
}

function PermissionGroupColumn({ title, parent, groups, keys }: {
    title: string,
    gi?: number,
    groups: PermissionGroup[],
    parent?: PermissionGroup
    keys: string[]
}) {
    const [sel, setSel] = useState<number>();
    const [grs, setGrs] = useState(groups);
    const [loading, setLoading] = useState(false);

    if (!grs.length && !loading) {
        let keyParts = parent?.Key?.split('~');
        if (keyParts?.length === 2) {
            setLoading(true);
            Proxy.get(keyParts[0] + '/PermissionGroups?id=' + keyParts[1]).then(x => {
                setGrs(x);
                setLoading(false);
            });
        }
    }

    return <>
        <Box px={3} sx={sel !== undefined ? { borderRight: '1px solid', borderColor: grey[300] } : undefined}>
            <Typography pt={2} variant="h6" sx={{ cursor: 'pointer' }} onClick={() => setSel(undefined)}>{title}</Typography>
            <List disablePadding>
                {grs.map((x, i) => <ListItem key={x.Key}
                    disablePadding
                    secondaryAction={x.Children ?
                        <IconButton edge="end" onClick={() => setSel(i)}>
                            <ChevronRight />
                        </IconButton> : null
                    }>
                    <ListItemButton dense selected={i === sel}>
                        {x.Key ?
                            <ListItemIcon>
                                <CheckBoxField
                                    edge="start"
                                    defaultChecked={keys.includes(x.Key)}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={e => {
                                        if (x.Key) {
                                            if (e.target.checked) {
                                                keys.push(x.Key);
                                            } else {
                                                let ki = keys.indexOf(x.Key);
                                                if (ki >= 0) {
                                                    keys.splice(ki, 1);
                                                }
                                            }
                                        }
                                    }}
                                />
                            </ListItemIcon> : null}
                        <ListItemText id={x.Key}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                {x.Name}
                                {x.Children?.length ? <Chip sx={{ ml: 1 }} label={x.Children?.length.toString()} /> : null}
                            </Box>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>)}
            </List>
        </Box>
        {(sel || (sel === 0)) ? <PermissionGroupColumn key={'next' + sel} parent={grs[sel]} title={grs[sel].Name || ''} groups={grs[sel].Children!} keys={keys} /> : null}
    </>;
}

export default function RoleForm({ apiRef, ...props }: { id: string, apiRef?: (api: EntityApi<Role, string>) => any }) {
    const [permissionGroups] = useRemoteData<PermissionGroup[]>('Role/PermissionGroups', null, 'controller');

    return <DataForm<Role, string> responseFormat="controller" id={props.id} proxy={proxy} onGet={x => { x.PermissionKeys || (x.PermissionKeys = []); return x; }}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField label="Наименование" name="userName" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth />
                </Box>

                {permissionGroups?.length ? <Box display="flex" flexDirection="row" className="permissions">
                    <PermissionGroupColumn
                        title="Ключи доступа"
                        groups={permissionGroups}
                        keys={entity.PermissionKeys} />
                </Box> : null}
            </>
        }}
    </DataForm>
}