import DataView from '../../components/DataView';
import { User } from '../../model/Types';
import UserForm from './Form';
import UsersTable from './Table';


export default function DashboardsView() {
    return <DataView<string, User>
        table={(onSelectRow) => <UsersTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <UserForm apiRef={apiRef} />}
    />;
}