import Observer from "./Observer";
import { Tenant } from "./Types";

type UserTenant = {
    Roles?: any[]
} & Tenant

var _tenant: UserTenant | null | undefined;

const observer = new Observer();

const fireUpdate = () => observer.fire('update', [_tenant]);

export const getTenant = () => _tenant;
export const getTenantId = () => (_tenant && _tenant.Id);
export const getRoles = () => (_tenant && _tenant.Roles);

export const listenTenant = (fn: (tenant: UserTenant | null | undefined) => void) => observer.listen('update', fn);

export const setTenant = function (tenant: UserTenant | null | undefined) {
    _tenant = tenant;
    //storeTenant(tenant ? tenant.id : null);
    return fireUpdate();
}

const storeTenant = (id: number) => {
    //   id ? localStorage.setItem('tenant', id.toString()) : localStorage.removeItem('tenant');
}
export const resolveTenant = function () {
    return getTenantId();
    // var acc = parseInt(localStorage.getItem('tenant'));
    // if (acc && !isNaN(acc) && isFinite(acc)) {
    // return acc;
    // }
}