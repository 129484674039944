import { AutoFixHighRounded } from "@mui/icons-material";
import { InlineDataTableProps } from "../../components/table/InlineDataTable";
import { MetaField } from "../../model/meta/Field";
import { Button } from "@mui/material";
import { TableField } from "../../model/meta/CompositeField";
import { IStep } from "../../model/Types";

function fieldsBinding(step: IStep) {
    return (f: MetaField, p: any) => {
        if (TableField.is(f) && f.Code === 'FieldsBinding') {
            let ip = p as InlineDataTableProps<any>;
            ip.toolbar!.append = tp => <Button
                component="label"
                startIcon={<AutoFixHighRounded />}
                variant="contained"
                color="primary"
                onClick={() => {
                }}>��������� ����</Button>;
        }

        return p;
    }
}

export default [
    fieldsBinding
] as ((step: IStep) => <TProps>(field: MetaField, props: TProps) => TProps)[];