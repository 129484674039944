import { Switch, SwitchProps, styled, useTheme } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { EmdTheme } from '../../theme';


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, color }) => ({
    width: '2rem',
    height: 'calc(1rem + 4px)',
    padding: 0,
    marginRight: '.5em',
    marginLeft: '.5em',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(calc(1rem - 4px))',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: (color || theme.components?.MuiSwitch?.defaultProps?.color) || (theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466'),
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: '1rem',
        height: '1rem',
    },
    '& .MuiSwitch-track': {
        borderRadius: 'calc(.75rem + 2px)',
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function CheckBoxField(props: SwitchProps & CheckboxProps) {
    const theme = useTheme<EmdTheme>();
    if (theme.checkbox === 'ios') {
        return <IOSSwitch {...props} />;
    } else if (theme.checkbox === 'switch') {
        return <Switch {...props} />;
    } else {
        return <Checkbox {...props} />;
    }
}