import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectProps, TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import CheckBoxField from '../../components/fields/CheckBoxField';
import DataForm, { EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import { connectionTypeTitles } from '../../model/EnumTitles';
import { ConnectionType } from '../../model/Enums';
import { Connection, IdName } from '../../model/Types';

const proxy: FormProxy<Connection> = {
    get: id => 'Connection/Get/' + id,
    save: e => 'Connection/Save',
    delete: e => 'Connection/Delete'
}

const connectionTypeArrays: IdName[] = [];
for (var ct in connectionTypeTitles) {
    connectionTypeArrays.push({
        Id: parseInt(ct),
        Name: (connectionTypeTitles as any)[ct]
    });
}

function ConnectionTypeCombo(props: SelectProps) {
    return <FormControl fullWidth>
        <InputLabel id={'label-connection'}>Тип соединения</InputLabel>

        <Select labelId={'label-connection'} label="Тип соединения" name="type" {...props}>
            {connectionTypeArrays?.map(x => <MenuItem value={x.Id}>{x.Name}</MenuItem>)}
        </Select>
    </FormControl>;
}

function ConnectionConfig({ entity }: { entity: Connection }) {
    function applyConfig(setter: (config: any) => void) {
        if (entity) {
            if (!entity.Config) {
                entity.Config = {};
            }

            setter(entity.Config);
        }
    }

    function TextConfig({ name, ...props }: TextFieldProps) {
        return <TextField name={name} defaultValue={name && entity?.Config && entity?.Config[name]} onChange={e => applyConfig(c => name && (c[name] = e.target.value))} fullWidth {...props} />;
    }

    switch (entity.Type) {
        case ConnectionType.RTA:
            return <Box gap={2} display="flex">
                <TextConfig label="Токен" name="externalToken" />
                <TextConfig label="Токен для тестирования" name="externalTokenDebug" />
            </Box>
        default: return <></>;
    }
}

export default function ConnectionForm({ apiRef, ...props }: IdFormProps<Connection> & { apiRef?: (api: EntityApi<Connection>) => any }) {
    const [type, setType] = useState(ConnectionType.Url);

    function onGet(c: Connection) {
        const con = new Connection();
        Object.assign(con, c);

        setType(con.Type);

        return props.onGet ? props.onGet(con) : con;
    }

    function onSubmit(c: Connection) {
        return {
            ...c,
            Config: c.Config && JSON.stringify(c.Config)
        } as Connection;
    }

    return <DataForm<Connection> proxy={proxy} responseFormat="controller" {...props} onGet={onGet} onSubmit={onSubmit}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <>
                <Box gap={3} display="flex">
                    <TextField label="Наименование" name="Name" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth />
                    <FormControlLabel
                        control={<CheckBoxField defaultChecked={entity?.Shared} onChange={e => entity && (entity.Shared = e.target.checked)} />}
                        label="Общий доступ"
                        sx={{ whiteSpace: 'nowrap' }}
                    />
                </Box>

                <Box gap={3} display="flex">
                    <FormControl fullWidth>
                        <InputLabel id="type">Тип соединения</InputLabel>
                        <ConnectionTypeCombo defaultValue={entity.Type} onChange={e => entity && setType(entity.Type = parseInt(e.target.value as string))} />
                    </FormControl>

                    <TextField label="Строка соединения" name="ConnectionString" defaultValue={entity?.ConnectionString} onChange={e => entity && (entity.ConnectionString = e.target.value)} fullWidth />
                </Box>

                <ConnectionConfig key={'config' + type} entity={entity} />
            </>
        }}
    </DataForm>
}