import { AddRounded, ContentCopyTwoTone, HorizontalRuleRounded, OpenInNew, RemoveRounded } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Link, MenuItem, Select, SelectProps, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { ParametersForm } from '../../components/ParametersForm';
import { HtmlJsChips } from '../../components/UrlField';
import DataForm, { EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import { DataTable, dataTableStyles } from '../../components/table/DataTable';
import { useRemoteData } from '../../model/Hooks';
import { root } from '../../model/Proxy';
import { Parameter, ReactDashboard, ReactDashboardArchive, SqlDataSource } from '../../model/Types';
import { DataSourceFormFields } from '../datasources/Form';
import { dataSourcesTableCells } from '../datasources/Table';
import './dashboards.css';
import CheckBoxField from '../../components/fields/CheckBoxField';

const dashboardDSColumns = dataSourcesTableCells.concat([{
    id: 'Id',
    label: '',
    config: {
        sx: dataTableStyles.actionsCell
    },
    renderer: x => <>
        <IconButton sx={dataTableStyles.cellButton}><ContentCopyTwoTone className="icon-one-tone" sx={dataTableStyles.cellButtonIcon} /></IconButton>
        <IconButton sx={dataTableStyles.cellButton} color="error"><RemoveRounded sx={dataTableStyles.cellButtonIcon} /></IconButton>
    </>
}]);

function TemplateCombo(props: SelectProps) {
    const [rows] = useRemoteData<ReactDashboardArchive[]>('ReactDashboardArchive/FullList', null, 'controller');
    return <Select key={rows?.length} labelId="templateId" label="Шаблон" name="templateId" required {...props}>
        {rows?.map(x => <MenuItem value={x.Id}>{x.Name ? x.Name + ' (' + x.FileName + ')' : x.FileName} от {moment(x.Uploaded).format('lll')}</MenuItem>)}
    </Select>
}

function EnpointForm({ endpoints, guid }: { endpoints: string[], guid?: string }) {
    const [parameterKey, setParameterKey] = useState(0);
    if (!endpoints.length) {
        endpoints.push('');
    }

    return <>
        <Typography variant="h6" display="flex" className="url-field-wrapper">
            URL адрес(а)
            <Button
                endIcon={<AddRounded />}
                color="success"
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={e => {
                    endpoints.push('');
                    setParameterKey(parameterKey + 1);
                }}>
                Добавить
            </Button>
            <div style={{ flex: 1 }} />
            {guid ? <HtmlJsChips guid={guid} path="dashboard" /> : null}
        </Typography>

        <Table>
            <TableBody>
                {
                    endpoints?.map((x, i) => <TableRow>
                        <TableCell width={20} sx={{ border: 'none', p: 1 }}>
                            <Typography>{i + 1}.</Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none', p: 1 }}>
                            <TextField
                                value={'/' + x}
                                onChange={e => {
                                    var v = e.target.value;
                                    while (v?.length && v[0] === '/') {
                                        v = v.substring(1);
                                    }

                                    endpoints[i] = v;
                                    setParameterKey(parameterKey + 1);
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={e => navigator.clipboard.writeText('/' + endpoints[i])}>
                                                <ContentCopyTwoTone fontSize="small" className="icon-one-tone" />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                fullWidth />
                        </TableCell>
                        <TableCell width={1} sx={{ border: 'none', p: 1 }}>
                            <InputAdornment position="start">
                                <Link color="inherit" href={root + x} target="_blank" sx={{ display: 'flex' }}>
                                    <OpenInNew color="inherit" />
                                </Link>
                            </InputAdornment>
                        </TableCell>
                        <TableCell width={1} sx={{ border: 'none', p: 1 }}>
                            <IconButton
                                color="error"
                                size="small"
                                onClick={e => {
                                    endpoints.splice(i, 1);
                                    setParameterKey(parameterKey + 1);
                                }}>
                                <HorizontalRuleRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
                }
            </TableBody>
        </Table >
    </>;
}

function DashboardDataSourceForm({ dataSources }: { dataSources: SqlDataSource[] }) {
    const [ds, setDs] = useState<number>();
    const [parameterKey, setParameterKey] = useState(1);

    return <>
        <Box display="flex" flex={1} gap={2}>
            <DataTable<SqlDataSource>
                columns={dashboardDSColumns}
                data={dataSources}
                load={() => Promise.resolve(dataSources)}
                onSelectRow={(e, r, i) => {
                    setDs(i);
                    return false;
                }}
                boxProps={{
                    style: { flex: 'none' }
                }}
                paperProps={{
                    style: { width: 250, minWidth: 150 }
                }}
                toolbar={{
                    allowAdd: false,
                    title: 'Источники',
                    append: tp => <Button
                        endIcon={<AddRounded />}
                        sx={{ ml: 'auto' }}
                        color="success"
                        variant="outlined"
                        onClick={e => {
                            dataSources.push({
                                Id: 0,
                                Name: 'Источник данных ' + parameterKey,
                                SqlText: '',
                                Parameters: []
                            });

                            setParameterKey(parameterKey + 1);
                        }}>Добавить</Button>
                }} />
            {!ds && ds !== 0 ? null :
                <Box flex={1} gap={2} display="flex" flexDirection="column">
                    <DataSourceFormFields key={'dsform' + ds} entity={dataSources[ds]} parameters={dataSources[ds].Parameters} readOnly={true} />
                </Box>
            }
        </Box>
    </>;
}

const proxy: FormProxy<ReactDashboard> = {
    get: id => 'ReactDashboard/Get/' + id,
    save: e => 'ReactDashboard/Save',
    delete: e => 'ReactDashboard/Delete'
}

export default function DashboardForm({ apiRef, ...props }: IdFormProps<ReactDashboard> & { apiRef?: (api: EntityApi<ReactDashboard>) => any }) {
    const [parameters, setParameters] = useState<Parameter[]>([]);
    const [sid, ssid] = useState(props.id);

    function onGet(x: ReactDashboard) {
        setParameters(x.Parameters || []);
        if (!x.DataSources) {
            x.DataSources = [];
        }

        return x;
    }

    function onSubmit(entity: ReactDashboard) {
        entity.Parameters = parameters;
        return entity;
    }

    function onSaved(entity: ReactDashboard) {
        if (sid) {
            ssid(sid + 1);
        }

        props.onSaved && props.onSaved(entity);
    }

    return <DataForm<ReactDashboard> responseFormat="controller" {...props} proxy={proxy} onSubmit={onSubmit} onGet={onGet} onSaved={onSaved}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <>
                <Box gap={2} display="flex" flexDirection="column">
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} gap={2}>
                        <TextField label="Наименование" name="name" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth required />
                        <FormControlLabel
                            control={<CheckBoxField defaultChecked={entity?.Shared} onChange={e => entity && (entity.Shared = e.target.checked)} />}
                            label="Общий доступ"
                            sx={{ whiteSpace: 'nowrap' }} />
                    </Box>

                    <FormControl>
                        <InputLabel id="TemplateId">Шаблон *</InputLabel>
                        <TemplateCombo name="TemplateId" defaultValue={entity.TemplateId} onChange={e => entity && (entity.TemplateId = parseInt(e.target.value as string))} />
                    </FormControl>
                </Box>

                <Box gap={2} display="flex" flexDirection="column">
                    <EnpointForm endpoints={entity.Endpoints} guid={entity?.Guid} />
                </Box>

                <Box gap={2} display="flex" flexDirection="column">
                    <ParametersForm parameters={parameters} />
                </Box>

                <DashboardDataSourceForm key={'ds' + sid} dataSources={entity.DataSources} />
            </>
        }}
    </DataForm>
}