import DataView from '../../components/DataView';
import { useRoute } from '../../model/Hooks';
import { ReactDashboardArchive } from '../../model/Types';
import TemplateForm from './Form';
import TemplatesTable from './Table';

export default function TemplatesView() {
    const [route] = useRoute();

    return <DataView<number, ReactDashboardArchive>
        table={(onSelectRow) => <TemplatesTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <TemplateForm id={id} onSaved={e => route.setState('id', e.Id)} onDeleted={e => route.setState([['id']])} apiRef={apiRef} />}
    />;
}