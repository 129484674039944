import { AddRounded, CheckRounded, ClearRounded, ContentCopyTwoTone, Edit, Folder, HorizontalRuleRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, SxProps, TextField } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper, { PaperProps } from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import route from '../../Router';
import { ResponseFormat, useRemoteData, useRoute } from '../../model/Hooks';
import Proxy from '../../model/Proxy';
import { Group, IEntity, IdName } from '../../model/Types';
import { EmdTheme } from '../../theme';
import { addUrlParams } from '../../utils';
import { LocationDialog, OpenProps } from '../Location';
import { RemoveDialog } from '../RemoveDialog';
import './datatable.css';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export interface TableColumn<T> {
    disablePadding?: boolean;
    id: keyof T;
    label: string;
    numeric?: boolean;
    renderer?: (data: T) => React.ReactNode;
    config?: TableCellProps
    columns?: TableColumn<T>[]
}

export interface HierarchicalTableColumn<T> extends TableColumn<T> {
    path?: HierarchicalTableColumn<T>[]
}

export interface TableApi<T> {
    reload: () => Promise<T[]>
}

export interface TableProps<T = any> {
    selectable?: boolean;
    allowRemove?: boolean
    numSelected?: number;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectRow?: (event: React.MouseEvent<unknown>, data: T, index: number) => boolean | void;
    onDeleteRow?: (event: React.MouseEvent<unknown>, data: T, index: number) => boolean | void;
    refApi?: React.RefCallback<TableApi<T>>
    order?: Order;
    orderBy?: keyof T;
    rowCount?: number;
    toolbar?: EnhancedTableToolbarConfig
}

export interface BaseDataTableProps<T> extends TableProps<T> {
    columns: TableColumn<T>[];
    groupName?: string
    typeId?: string
    boxProps?: BoxProps
    paperProps?: PaperProps
}

interface DataTableProps<T> extends BaseDataTableProps<T> {
    data?: T[] | null
    load: () => Promise<T[]>
}

interface RemoteDataTableProps<T> extends BaseDataTableProps<T> {
    url: string;
    deleteUrl?: string;
    responseFormat?: ResponseFormat
}

type CellMap<T> = {
    col?: HierarchicalTableColumn<T>,
    last?: boolean,
    rowSpan: number,
    colSpan: number
} | undefined;

function EnhancedTableHead<T>({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, selectable, ...props }: BaseDataTableProps<T>) {
    const columns = props.columns as HierarchicalTableColumn<T>[];

    const headHeight = (columns.map(x => x.path?.length).reduce((a, b) => !a || (b && (a < b)) ? b : a, 0) || 0) + 1;

    const cellMap = React.useMemo(() => {
        let row: CellMap<T>[] = columns.map(x => {
            return {
                col: x,
                last: true,
                rowSpan: 1,
                colSpan: 1
            }
        });

        const map = [row];
        while (row.find(x => x?.col?.path?.length)) {
            let nextRow = row.reduce((res, x, i) => {
                if (x) {
                    let p = x.col?.path;
                    if (p?.length) {
                        let col = p[p.length - 1];
                        let pp = res[res.length - 1];
                        if (col !== pp?.col) {
                            res.push({
                                col,
                                rowSpan: 1,
                                colSpan: 1
                            });
                            return res;
                        } else if (pp.rowSpan > 1) {
                            row[i - 1] = {
                                rowSpan: pp.rowSpan - 1,
                                colSpan: pp.colSpan
                            };
                            pp.rowSpan = 1;
                        }
                    } else {
                        const cell = row[i]!;
                        row[i] = undefined;
                        cell.rowSpan++;
                        res.push(cell);
                        return res;
                    }
                }
                res.push(undefined);
                return res;
            }, [] as CellMap<T>[]);

            nextRow.forEach((x, i) => {
                if (x) {
                    let next = nextRow.findIndex((x, j) => x && (i < j));
                    x.colSpan = (next > 0 ? nextRow.findIndex((x, j) => x && (i < j)) : nextRow.length) - i;
                }
            });

            map.push(row = nextRow);
        }

        return map.reverse();
    }, [props.columns])

    const createSortHandler = (property: keyof T) =>
        (event: React.MouseEvent<unknown>) =>
            onRequestSort && onRequestSort(event, property);


    return <TableHead>
        {cellMap.map((row, i) => <TableRow key={i}>
            {(selectable && i) ? <TableCell key="checkbox" padding="checkbox" rowSpan={cellMap.length}>
                <Checkbox
                    color="primary"
                    indeterminate={!!(numSelected && rowCount && rowCount > 0 && numSelected < rowCount)}
                    checked={!!(rowCount && rowCount > 0 && numSelected === rowCount)}
                    onChange={onSelectAllClick}
                />
            </TableCell> : null}

            {row.map((headCell) => !headCell ? null : headCell.col? <TableCell
                rowSpan={headCell.rowSpan}
                colSpan={headCell.colSpan}
                key={headCell.col.id.toString()}
                align={headCell.colSpan > 1 ? 'center' : (headCell.col.numeric ? 'right' : 'left')}
                padding={headCell.col.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.col.id ? order : false}
            >{headCell.last ?
                <TableSortLabel
                    active={orderBy === headCell.col.id}
                    direction={orderBy === headCell.col.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.col.id)}
                >
                    {headCell.col.label}
                    {orderBy === headCell.col.id ? <Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null}
                </TableSortLabel> :
                headCell.col.label}
            </TableCell> : <TableCell rowSpan={headCell.rowSpan} colSpan={headCell.colSpan} />)}
        </TableRow>)}
    </TableHead>;
}

interface EnhancedTableToolbarConfig {
    title?: React.ReactNode
    icon?: React.ReactNode
    prepend?: (tp: EnhancedTableToolbarProps) => React.ReactNode;
    append?: (tp: EnhancedTableToolbarProps) => React.ReactNode;
    allowAdd?: boolean
}

interface EnhancedTableToolbarProps extends EnhancedTableToolbarConfig {
    groupName?: string
    GroupId?: string
    numSelected: number;
    reload: () => any;
    onGroupAdded?: (group: Group) => any;
    onShowAllChange?: (showAll: boolean) => any;
}

function GroupDialog({ onGroupAdded, grApi, root, GroupId, groupName }: {
    onGroupAdded?: (group: Group) => any,
    grApi?: GroupsViewApi,
    root?: Group,
    groupName?: string
    GroupId?: string
}) {
    const [open, setOpen] = React.useState<boolean | null>(false);

    var groupIds = route.get<IdName[]>('path');

    return <>
        <Button component="label" startIcon={<AddRounded />} variant="outlined" color="success" disabled={!groupName || !(GroupId || root)} onClick={() => setOpen(true)}>Группа</Button>
        <Dialog open={open !== false}
            className="location-dialog"
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    Proxy.post('Group/Save', {
                        name: new FormData(event.currentTarget).get('name'),
                        GroupId: groupIds.length ? groupIds[groupIds.length - 1].Id : root?.Id
                    }).then(x => {
                        setOpen(false);
                        onGroupAdded && onGroupAdded(x as Group);
                        grApi?.add(x as Group);
                    }, x => {
                        debugger;
                        setOpen(true);
                    });

                    setOpen(null);
                },
            }}
        >
            <DialogTitle>Создание группы</DialogTitle>
            <DialogContent>
                <TextField autoFocus required margin="dense" name="name" label="Наименование" fullWidth />
            </DialogContent>
            <DialogActions disableSpacing sx={{ p: 2, gap: 1 }}>
                <Button startIcon={<CheckRounded />} type="submit" disabled={!open} color="success">Сохранить</Button>
                <Button startIcon={<ClearRounded />} onClick={() => setOpen(false)} color="warning">Отмена</Button>
            </DialogActions>
        </Dialog>
    </>;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;
    const [showAll, setShowAll] = React.useState(false);

    const prepend = props.prepend && props.prepend(props);
    const append = props.append && props.append(props);
    if (!prepend && !append && props.allowAdd === false && !numSelected) {
        return <></>;
    }

    function changeShowAll() {
        if (!props.onShowAllChange || (props.onShowAllChange(!showAll) !== false)) {
            setShowAll(!showAll);
            route.setState('showAll', !showAll);
        }
    }

    return <>
        <Toolbar
            sx={{
                gap: 1,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {props.title ? <Typography variant="h6" className="title" mr={1}>{props.title}</Typography> : null}
            {prepend}
            {props.icon ? <Button startIcon={props.icon} variant={showAll ? 'contained' : 'outlined'} color="primary" onClick={changeShowAll}>Без групп</Button> : null}
            {props.allowAdd === false ? null : <Button component="label" startIcon={<AddRounded />} variant="contained" color="success" {...{ "data-route": "id=new" }}>Добавить</Button>}
            {/*<GroupDialog grApi={grApi} root={root} GroupId={props.GroupId} groupName={props.groupName} />*/}
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    выбрано {numSelected}
                </Typography>
            ) : null}
            {/**numSelected > 0 ? (
                <Tooltip title="Delete" sx={{ ml: 'auto' }}>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list" sx={{ ml: 'auto' }}>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )/**/}
            {append}
        </Toolbar>

        {/**props.groupName ? <GroupsView type={props.groupName} onRoot={x => setRoot(x)} apiRef={setGrApi} /> : null/**/}
    </>;
}

export default function RemoteDataTable<T extends IEntity<TKey>, TKey = number>(props: RemoteDataTableProps<T>) {
    const [route] = useRoute();
    var groupIds = route.get<IdName[]>('path');
    var showAll = route.get<boolean>('showAll');

    const [data, load] = useRemoteData<T[]>(addUrlParams(
        props.url,
        showAll ? { showAll: true } : groupIds?.length ? { GroupId: groupIds[groupIds.length - 1].Id } : null),
        undefined,
        props.responseFormat);

    return <DataTable<T, TKey> {...props} data={data} load={load} />;
}

interface GroupsViewApi { add: (gr: Group) => any };

function GroupsTable({ groupName }: { groupName?: string }) {
    const [root, setRoot] = React.useState<Group>();
    const [grApi, setGrApi] = React.useState<GroupsViewApi>();
    const [route] = useRoute();

    var groupIds = route.get<IdName[]>('path');

    var url = 'Group/';
    if (groupIds?.length) {
        url += 'ByGroup?GroupId=' + groupIds[groupIds.length - 1].Id;
    } else {
        url += 'Root?typeId=' + groupName;
    }

    const [data] = useRemoteData<Group[]>(url,
        grs => { grs.filter(x => !x.GroupId).forEach(setRoot); return grs; },
        'controller');

    const [_dl, setDL] = React.useState(data?.length);

    React.useMemo(() => {
        setGrApi && setGrApi({
            add: gr => {
                data?.push(gr);
                setDL(data?.length)
            }
        })
    }, [data]);

    const [removing, setRemoving] = React.useState<number>();
    function handleRemove(e: React.MouseEvent<HTMLButtonElement>, row: Group) {
        e.stopPropagation();
        setRemoving(row.Id);
    }

    const [open, setOpen] = React.useState<[number, OpenProps]>();
    function handleEdit(e: React.MouseEvent<HTMLButtonElement>, row: Group) {
        e.stopPropagation();

        setOpen([row.Id, {
            id: row.Id,
            name: row.Name,
            groupId: row.GroupId
        }]);
    }

    return <Box className="groups-table">        
        <Toolbar>
            <Typography variant="h6" className="title" mr={1}>{groupIds?.length ? groupIds[groupIds.length-1].Name : 'Группы'}</Typography>
            <GroupDialog grApi={grApi} root={root} groupName={groupName} />
        </Toolbar>

        {data ?
            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TableBody>
                        {data?.filter(x => x.GroupId).map((row, index) => {
                            const isItemSelected = row.Id === -1;

                            return <TableRow
                                hover
                                onClick={() => route.setState([['id'], ['path', route.get<IdName[]>('path').concat(row as IdName)]])}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.Id?.toString()}
                                selected={isItemSelected}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell>{row.Name}</TableCell>
                                <TableCell sx={dataTableStyles.actionsCell}>
                                    <IconButton sx={dataTableStyles.cellButton} onClick={e => handleEdit(e, row)}><Edit sx={dataTableStyles.cellButtonIcon} /></IconButton>
                                    <IconButton color="error" sx={dataTableStyles.cellButton} onClick={e => handleRemove(e, row)}><HorizontalRuleRounded sx={dataTableStyles.cellButtonIcon} /></IconButton>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer> : <div className="data-loading-text">Загрузка данных</div>}

        {groupName && open ? <LocationDialog title={root?.Name} typeId={groupName} open={open[1]} onClose={() => setOpen(undefined)} /> : null}
        {removing ? <RemoveDialog typeId="Group" id={removing} onClose={() => setRemoving(undefined)} /> : null}
    </Box>;
}

export function DataTable<T extends IEntity<TKey>, TKey = number>(props: DataTableProps<T>) {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof T>();
    const [selected, setSelected] = React.useState<readonly TKey[]>([]);
    const [selectable, setSelectable] = React.useState(false);

    const rows = props.data;
    const reload = props.load;

    const columns = React.useMemo(() => {
        const res: TableColumn<T>[] = [];
        function mapColsRecursive(c: TableColumn<T>, p?: HierarchicalTableColumn<T>[]) {
            const hc = c as HierarchicalTableColumn<T>;
            if (p) {
                hc.path = p;
            }

            if (c.columns) {
                c.columns.forEach(x => mapColsRecursive(x, p ? [...p, hc] : [hc]));
            } else {
                if (p) {
                    const r = c.renderer;
                    c.renderer = e => {
                        var d = e as any;
                        p.forEach(x => d = d && d[x.id]);
                        return r ? r(d) : (d ? d[c.id]?.toLocaleString() : null);
                    }
                }

                res.push(c);
            }
        }

        props.columns.forEach(x => mapColsRecursive(x));

        return res;
    }, [props.columns]);

    const [removing, setRemoving] = React.useState<TKey>();
    function handleRemove(e: React.MouseEvent<HTMLButtonElement>, row: T, index: number) {
        e.stopPropagation();
        if (!props.onDeleteRow || props.onDeleteRow(e, row, index) !== false) {
            setRemoving(row.Id);
        }
    }

    const [move, setMove] = React.useState<[TKey, OpenProps]>();
    function handleMove(e: React.MouseEvent<HTMLButtonElement>, row: any) {
        e.stopPropagation();

        setMove([row.Id, {
            id: row.Id,
            name: props.groupName,
            groupId: row.GroupId
        }]);
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof T,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && rows) {
            const newSelected = rows.map((n) => n.Id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    function handleCopy(e: React.MouseEvent<HTMLButtonElement>, row: T) {
        selectRow(e, row, true);
    }

    function handleClick(e: React.MouseEvent<unknown>, data: T) {
        selectRow(e, data, false);
    };

    function selectRow(e: React.MouseEvent<unknown>, data: T, copy: boolean) {
        if (selectable && !copy) {
            const selectedIndex = selected.indexOf(data.Id);
            let newSelected: readonly TKey[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, data.Id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            setSelected(newSelected);
        } else {
            route.setState([['id', data.Id], ['copy', copy]]);
            e.stopPropagation();
        }

    }

    props.refApi && props.refApi({
        reload
    });

    const isSelected = (id: TKey) => selectable ? selected.indexOf(id) !== -1 : (route.get('id') == id);

    const visibleRows = rows && (orderBy ? stableSort(rows, order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)) : rows);

    return <Box display="flex" flexDirection="row" className="datatable-wrapper" flex={1} {...props.boxProps}>
        {props.groupName ? <>
            <GroupsTable groupName={props.groupName}  {...props.toolbar} />
            <Divider orientation="vertical" sx={{ mx: 1 }} />
        </> : null}
        <Paper elevation={0} {...props.paperProps} sx={{ border: 'none', width: '100%', flex: 1, ...props.paperProps?.sx }}>
            <EnhancedTableToolbar numSelected={selected.length} reload={reload} groupName={props.groupName} {...props.toolbar} />
            {visibleRows && rows ?
                <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            {...props}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            columns={columns}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.Id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return <TableRow
                                    hover
                                    onClick={(event) => {
                                        if (!props.onSelectRow || props.onSelectRow(event, row, index) !== false) {
                                            handleClick(event, row);
                                        }
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.Id?.toString()}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >{selectable ?
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell> : null}
                                    {columns.map((x, i) =>
                                        <TableCell key={i} {...x.config}>{x.renderer ? x.renderer(row) : row[x.id]?.toLocaleString()}</TableCell>)}

                                    <TableCell sx={dataTableStyles.actionsCell}>
                                        {props.groupName ? <IconButton sx={dataTableStyles.cellButton} onClick={e => handleMove(e, row)}>
                                            <Folder sx={dataTableStyles.cellButtonIcon} />
                                        </IconButton> : null}

                                        {(props.allowRemove === false) || !props.typeId ? null :
                                            <IconButton sx={dataTableStyles.cellButton} onClick={e => handleCopy(e, row)}>
                                                <ContentCopyTwoTone className="icon-one-tone" sx={dataTableStyles.cellButtonIcon} />
                                            </IconButton>}

                                        {(props.allowRemove === false) || (!props.typeId && !props.onDeleteRow) ? null :
                                            <IconButton color="error" sx={dataTableStyles.cellButton} onClick={e => handleRemove(e, row, index)}>
                                                <HorizontalRuleRounded sx={dataTableStyles.cellButtonIcon} />
                                            </IconButton>}
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer> : <div className="data-loading-text">Загрузка данных</div>}
        </Paper>
        {props.groupName && move ? <LocationDialog
            title={props.toolbar?.title}
            id={move[0]}
            typeId={props.groupName}
            responseFormat={((props as BaseDataTableProps<T>) as RemoteDataTableProps<T>).responseFormat}
            open={move[1]}
            onClose={() => setMove(undefined)} /> : null}
        {props.typeId && removing ? <RemoveDialog
            typeId={props.typeId}
            id={removing}
            responseFormat={((props as BaseDataTableProps<T>) as RemoteDataTableProps<T>).responseFormat}
            onClose={() => setRemoving(undefined)} /> : null}
    </Box>;
}

export const dataTableStyles: { [name: string]: SxProps<EmdTheme> } = {
    actionsCell: {
        pr: 0,
        pl: 0,
        width: 0,
        whiteSpace: 'nowrap'
    },
    cellButton: {
        ml: .75,
        width: '1.25rem',
        height: '1.25rem'
    },
    cellButtonIcon: {
        width: '.8rem'
    }
}