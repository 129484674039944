import { AccessTimeRounded, AddRounded, AutoFixHighRounded, CalendarMonthRounded, CheckBoxOutlined, DataObjectRounded, Filter1Rounded, HorizontalRuleRounded, LooksOneOutlined, TableBarRounded, TableChartRounded, TitleRounded } from '@mui/icons-material';
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectProps, Toolbar, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { MouseEvent, ReactNode, useState } from 'react';
import route from '../../Router';
import DataTextField from '../../components/fields/DataTextField';
import { DataTabForm, EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import Proxy from '../../model/Proxy';
import { Metadata } from '../../model/Types';
import { fieldTypes } from '../../model/meta';
import CompositeField, { asCompositeField } from '../../model/meta/CompositeField';
import { MetaField } from '../../model/meta/Field';
import { deleteMeta, saveMeta } from '../../model/meta/MetaStore';
import { defaultStorageType, storageTypes } from '../../model/storages';
import StorageInfo from '../../model/storages/StorageInfo';

const proxy: FormProxy<Metadata> = {
    get: id => 'MetadataGet?id=' + (id > 0 ? id : 0),
    save: e => 'MetadataSave',
    delete: e => 'MetadataDelete'
}

const fieldIcons: any = {
    "field.string": <TitleRounded fontSize="small" />,
    "field.int": <LooksOneOutlined fontSize="small" />,
    "field.double": <Filter1Rounded fontSize="small" />,
    "field.bool": <CheckBoxOutlined fontSize="small" />,
    "field.date": <CalendarMonthRounded fontSize="small" />,
    "field.datetime": <AccessTimeRounded fontSize="small" />,
    "field.composite": <DataObjectRounded fontSize="small" />,
    "field.table": <TableChartRounded fontSize="small" />
}

function FieldTreeItem({ parents, field, onSelect }: { parents?: string[], field: MetaField, onSelect: (field: MetaField) => any }) {
    const composite = field as CompositeField;

    return <TreeItem
        itemId={(parents ? parents?.join('.') + '.' : '') + (field.Code || '')}
        onClick={() => onSelect(field)}
        label={<Typography display="flex" gap={.5} alignItems="center">
            {fieldIcons[field['$type']] || fieldIcons["field.int"]}
            {field.Name || field.Code}
        </Typography>}>
        {composite.Fields?.map((x: MetaField) => <FieldTreeItem parents={parents ? [...parents, composite.Code] : [composite.Code]} field={x} onSelect={onSelect} />)}
    </TreeItem>;
}

function FieldsTree({ fields, onSelect, onAutoFill }: { fields: MetaField[], onSelect: (field: MetaField) => any, onAutoFill:()=>any }) {
    const [selected, setSelected] = useState<string | null>();
    const [treeKey, setTreeKey] = useState(0);

    function onAdd(e: MouseEvent<HTMLButtonElement>) {
        var level = fields;
        if (selected) {
            selected?.split('.').forEach(code => {
                const cf = asCompositeField(level.find(x => x.Code == code));
                if (cf) {
                    level = cf.Fields || (cf.Fields = []);
                }
            });
        }

        const newField = {
            "$type": 'field.string',
            Options: {}
        };
        level.push(newField as any);

        setTreeKey(treeKey + 1);
        onSelect(newField as any);
    }

    function onDelete(e: MouseEvent<HTMLButtonElement>) {
        var level = fields;
        if (selected) {
            const rev = selected?.split('.').reverse();
            rev.slice(1).reverse().forEach(code => {
                const cf = asCompositeField(level.find(x => x.Code == code));
                if (cf) {
                    level = cf.Fields || (cf.Fields = []);
                }
            });

            if (level) {
                level.splice(level.findIndex(x => x.Code === rev[0]), 1);
                setTreeKey(treeKey + 1);
            }
        }
    }

    return <Paper className="dataform-wrapper" component="form" elevation={0} sx={{ maxWidth: '30%' }}>
        <Toolbar sx={{ gap: 1 }}>
            <Typography mr="auto">Поля</Typography>
            <Button variant="contained" color="primary" startIcon={<AutoFixHighRounded />} onClick={onAutoFill}>Добавить</Button>
            <Button variant="contained" color="success" startIcon={<AddRounded />} onClick={onAdd}>Добавить</Button>
            <Button variant="contained" color="error" disabled={!selected} startIcon={<HorizontalRuleRounded />} onClick={onDelete}>Удалить</Button>
        </Toolbar>
        <SimpleTreeView key={treeKey} onSelectedItemsChange={(e, i) => setSelected(i)}>
            {fields.map(x => <FieldTreeItem field={x} onSelect={onSelect} />)}
        </SimpleTreeView>
    </Paper>;
}

function FieldTypeCombo<T = MetaField>({ entity, name, field, label, ...props }: { entity?: T, field?: keyof T } & SelectProps) {
    name || (name = '$type');
    field || (field = '$type' as any);
    label || (label = 'Тип');

    return <FormControl fullWidth>
        <InputLabel id={'label-' + name}>{label}</InputLabel>

        <Select labelId={'label-' + name} label={label} name={name} defaultValue={entity && entity[field!]} {...props}>
            {fieldTypes.map(x => <MenuItem value={'field.' + x.TypeId}>{x.name}</MenuItem>)}
        </Select>
    </FormControl>;
}

function StorageForm({ label, value, onChange }: { label?: ReactNode, value: StorageInfo, onChange: (v: StorageInfo) => any }) {
    const [t, st] = useState(value['$type']);
    label = label || 'Тип источника';

    return <>
        <FormControl fullWidth>
            <InputLabel id={'label-' + label}>{label}</InputLabel>

            <Select labelId={'label-' + label} label={label} defaultValue={value['$type']} onChange={(e, item) => st(value['$type'] = e.target.value)}>
                {storageTypes.map(x => <MenuItem value={x.TypeId}>{x.name}</MenuItem>)}
            </Select>
        </FormControl>


        {storageTypes.find(x => x.TypeId === t)?.component(value, onChange)}
    </>;
}

export default function MetadataForm({ apiRef, ...props }: IdFormProps<Metadata> & { apiRef?: (api: EntityApi<Metadata>) => any }) {
    const [formApi, setFormApi] = useState<EntityApi<Metadata>>();
    const [fields, setFields] = useState<MetaField[]>([]);
    const [field, setField] = useState<MetaField>();
    const [treeKey, setTreeKey] = useState(0);

    function onGet(x: Metadata) {
        if (!x.StorageInfo) {
            x.StorageInfo = {
                $type: defaultStorageType
            };
        }

        setFields(x.Fields || []);
        return x;
    }

    function onSubmit(entity: Metadata) {
        entity.Fields = fields;
        return entity;
    }

    function onAutoFill() {
        if (formApi?.entityRef.current) {
            Proxy.post('MetadataAutoFill', formApi?.entityRef.current).then(x => {
                if (x.success && x.result && formApi?.entityRef) {
                    onGet(formApi.entityRef.current = x.result);
                }
            });
        }
    }

    return <DataTabForm<Metadata> {...props}
        proxy={proxy}
        onSubmit={onSubmit}
        onSaved={saveMeta}
        onDeleted={x => deleteMeta(x.Id)}
        onGet={onGet}
        toolbar={{
            actions: (tp, entity) => <>
                <Button color="info" onClick={e => {
                    e.preventDefault();
                    route.setPath('/data/dataview/' + entity?.TypeId)
                }} startIcon={<TableChartRounded />}>Данные</Button>
            </>
        }}
        tabs={[{ text: 'Основное' }, { text: 'Поля' }]}>
        {[(entity, api) => {
            apiRef && apiRef(api);
            setFormApi(api);
            return <>
                <Box display="flex" gap={2}>
                    <DataTextField entity={entity} label="Код" field="TypeId" fullWidth />
                    <DataTextField entity={entity} label="Наименование" field="Name" fullWidth />
                </Box>
                {/**<DataTextField entity={entity} label="Поле - наименование" field="FacePropertyName" />/**/}

                <StorageForm value={entity.StorageInfo} onChange={v => { entity.StorageInfo = v; }} />
            </>;
        }, (entity, api) => {
            apiRef && apiRef(api);
            setFormApi(api);
            return <Box display="flex" gap={2}>
                <FieldsTree fields={fields} onSelect={f => setField(f)} onAutoFill={onAutoFill} />
                {field ? <Box key={field.Code} flex={1} display="flex" flexDirection="column" gap={2}>
                    <DataTextField entity={field} label="Код" field="Code" required />
                    <DataTextField entity={field} label="Наименование" field="Name" />
                    <FieldTypeCombo entity={field} onChange={e => {
                        field && (field['$type'] = e.target.value as any);
                        setTreeKey(treeKey + 1);
                    }} />
                </Box> : null}
            </Box>;
        }]}
    </DataTabForm>
}