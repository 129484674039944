import { Component, ReactNode } from 'react';
import DataForm, { BaseDataFormProps, EntityApi, FormProxy, IdFormProps } from '../../components/form/DataForm';
import { IEntity, Metadata } from '../../model/Types';
import { MetaField } from '../../model/meta/Field';
import { getMetaByTypeId } from '../../model/meta/MetaStore';
import { fieldFormTypes } from './fields';
import { FormFieldProps } from './fields/FieldProps';

export type Entity = {
    [field: string]: any
} & IEntity;

interface EntityFormProps<T, K = number> extends IdFormProps<T, K> {
    typeId: string
    apiRef?: (api: EntityApi<T>) => any
    overrides?: (<TProps>(field: MetaField, props: TProps) => TProps)[]
}

export default class EntityForm<T extends IEntity = Entity> extends Component<EntityFormProps<T>>{
    protected _meta: Metadata;
    protected dataFormProps: BaseDataFormProps<T>
    protected fieldsMap: {
        field: MetaField,
        formField: (props: FormFieldProps) => ReactNode
    }[]

    constructor(p: EntityFormProps<T>) {
        super(p);

        const meta = getMetaByTypeId(p.typeId);
        if (meta) {
            this._meta = meta;
        } else {
            throw new Error(`Хранилище данных ${p.typeId} не найдено`);
        }

        this.dataFormProps = {
            ...p,
            
            proxy: this.buildPropxy()
        };

        this.fieldsMap = this._meta.Fields.map(x => {
            const typeId = x['$type'].split('.').filter((_, i) => i).join('.');
            return {
                field: x,
                formField: fieldFormTypes[typeId]
            };
        }).filter(x => x?.formField);
    }

    getDataFormProps() {
        return this.dataFormProps;
    }

    buildPropxy(): FormProxy<T> {
        return {
            get: id => this.props.typeId + 'Get?id=' + (id > 0 ? id : 0),
            save: e => this.props.typeId + 'Save',
            delete: e => this.props.typeId + 'Delete'
        };
    }

    propsApplier(field: MetaField) {
        if (!this.props.overrides?.length) {
            return (x: any) => x;
        }

        return (props: any) => {
            this.props.overrides?.forEach(x => {
                props = x(field, props);
            });

            return props;
        };
    }

    buildFields(enity?: T) {
        const fields = this.fieldsMap.filter(x => !x.field.Hidden).map(x => x.formField({
            field: x.field,
            meta: this._meta,
            entity: enity,
            parentObject: enity,
            propsApplier: this.propsApplier(x.field)
        }));

        return fields;
    }

    render() {
        return <DataForm<T> {...this.getDataFormProps()}>
            {(entity, api) => {
                this.props.apiRef && this.props.apiRef(api);
                return this.buildFields(entity);
            }}
        </DataForm>;
    }
}