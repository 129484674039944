const objType = typeof {};
export function isObject(v: any) {
    return !Array.isArray(v) && (typeof v === objType);
}

export function addUrlParams(url: string, params?: { [name: string]: any } | null) {
    if (!params) return url;

    var paramsArray: string[] = [];
    for (var k in params) {
        if (params[k]?.toString) {
            paramsArray.push(k + '=' + params[k].toString());
        }
    }

    if (!paramsArray.length) return url;

    return url + (url.includes('?') ? '&' : '?') + paramsArray.join('&');
}