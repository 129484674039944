import { Button, Input } from '@mui/material';
import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { IdName, ReactDashboardArchive } from '../../model/Types';
import { UploadFile } from '@mui/icons-material';
import Proxy from '../../model/Proxy';
import route from '../../Router';
import moment from 'moment';
import templatesIcon from '../../theme/mpt/templatesIcon';

const headCells: TableColumn<ReactDashboardArchive>[] = [
    {
        id: 'Name',
        label: 'Наименование',
    },
    {
        id: 'FileName',
        label: 'Файл',
    },
    {
        id: 'Uploaded',
        label: 'Дата создания/загрузки',
        renderer: x => moment(x.Uploaded).toDate() > new Date(2000, 1, 1) ? moment(x.Uploaded).format('LLL') : '',
        config: {
            align: 'left'
        }
    }
];

export default function TemplatesTable(props: TableProps<ReactDashboardArchive>) {
    return <DataTable
        columns={headCells}
        url="ReactDashboardArchive/List"
        responseFormat="controller"
        typeId="ReactDashboardArchive"
        groupName="ReactDashboardArchive"
        toolbar={{
            title: 'Шаблоны',
            icon: <div style={{ zoom: .5, display: 'flex' }}>{templatesIcon}</div>,
            prepend: props => <Button component="label" startIcon={<UploadFile />} variant="contained" color="success">
                Загрузить
                <Input type="file" name="file" style={{ display: 'none' }} onChange={e => {
                    const input = e.target as HTMLInputElement;
                    const l = input.files?.length;
                    if (l) {
                        const formData = new FormData();
                        for (var i = 0; i < l; i++) {
                            formData.append('file' + i, input.files![i]);
                        }

                        const path = route.get<IdName[]>('path');

                        Proxy.post('ReactDashboardArchive/Upload' + (path?.length ? '?groupId=' + path[path.length - 1].Id : ''), null, null, {
                            headers: {},
                            body: formData
                        }).then(id => {
                            if (id > 0) {
                                props.reload();
                            }

                            e.target.value = '';

                            route.setState('id', id);
                        });
                    }
                }} />
            </Button>
        }}
        {...props} />;
}