import CompositeField, { TableField } from "./CompositeField";
import { DoubleField, IntegerField, StringField, DateField, DateTimeField, BooleanField } from "./Field"

export const fieldTypes = [
    { TypeId: StringField.TypeId, name: 'Строка', create: () => new StringField() },
    { TypeId: IntegerField.TypeId, name: 'Целое число', create: () => new IntegerField() },
    { TypeId: DoubleField.TypeId, name: 'Число с плавающей точкой', create: () => new DoubleField() },
    { TypeId: BooleanField.TypeId, name: 'Логическое', create: () => new BooleanField() },
    { TypeId: DateField.TypeId, name: 'Дата', create: () => new DateField() },
    { TypeId: DateTimeField.TypeId, name: 'Дата и Время', create: () => new DateTimeField() },
    { TypeId: CompositeField.TypeId, name: 'Составное', create: () => new CompositeField() },
    { TypeId: TableField.TypeId, name: 'Таблица', create: () => new TableField() },
];