import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { IEntity } from '../../model/Types';
import { BaseDataTableProps, DataTable } from './DataTable';


const StyledBox = styled('div')(({ theme }) => `
    & .MuiTableBody-root .MuiTableCell-root {
        padding: 0;
    }

    & .MuiTableCell-root > .MuiFormControl-root {
        width: 100%;
    }

    & .MuiTableCell-root > .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    & .MuiTableCell-root > .MuiFormControl-root legend,
    & .MuiTableCell-root > .MuiFormControl-root label,
    & .MuiTableCell-root > .MuiFormControlLabel-root .MuiFormControlLabel-label {
        display: none;
    }
`);

export interface InlineDataTableProps<T> extends BaseDataTableProps<T> {
    data: T[]
}

export default function InlineDataTable<T extends IEntity<TKey>, TKey = number>({ data, toolbar, ...props }: InlineDataTableProps<T>) {
    const [key, sk] = useState(data.length);

    function onAdd() {
        data.push({} as T);
        sk(data.length);
    }

    return <StyledBox>
        <DataTable<T, TKey> key={key}
            {...props}
            data={data}
            onSelectRow={() => false}
            load={() => Promise.resolve(data)}
            selectable={true}
            allowRemove={true}
            onDeleteRow={(e, d, i) => {
                var index = data.indexOf(d);
                if (index > -1) {
                    data.splice(index, 1);
                    sk(data.length);
                }
            }}
            toolbar={{
                allowAdd: false,
                ...toolbar,
                append: tp => <>
                    {toolbar?.append && toolbar?.append(tp)}
                    <Button component="label" startIcon={<AddRounded />} variant="contained" color="success" onClick={onAdd}>Добавить</Button>
                </>,
                prepend: tp => <div style={{ flex: 1 }} />
            }} />
    </StyledBox>;
}