import { useState } from "react";
import StorageInfo from "../../model/storages/StorageInfo";
import RdbmsStorageInfo from "../../model/storages/RdbmsStorageInfo";
import { Box, FormControlLabel } from "@mui/material";
import DataTextField from "../fields/DataTextField";
import { ConnectionCombo } from "../../pages/connections";
import CheckBoxField from "../fields/CheckBoxField";

export default function RdbmsStorageForm({ value, onChange }: { value: StorageInfo, onChange: (v: RdbmsStorageInfo) => any }) {
    const [storage] = useState((value || {}) as RdbmsStorageInfo);

    return <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" gap={2}>
            <ConnectionCombo
                defaultValue={storage.ConnectionId}
                onChange={e => storage && (storage.ConnectionId = parseInt(e.target.value as string))} />
            <FormControlLabel
                control={<CheckBoxField defaultChecked={storage?.ReadOnly} onChange={e => storage && (storage.ReadOnly = e.target.checked)} />}
                label="Только для чтения"
                sx={{ whiteSpace: 'nowrap' }} />
        </Box>
        <Box display="flex" gap={2}>
            <DataTextField label="Схема" field="Schema" entity={storage} sx={{ flex: 1 }} />
            <DataTextField label="Имя таблицы" field="Name" entity={storage} sx={{ flex: 1 }} required />
        </Box>
    </Box>;
}