import Observer from "./Observer";
import Proxy from "./Proxy";
import { setTenant } from "./Tenant";
import { License, User } from "./Types";

var user: User & {
    License: License
}
var init = false;

const observer = new Observer();

const fireUserUpdate = () => observer.fire('update', [user]);

export const getUser = () => user;

export const isAuthentificated = () => (user && user.IsAuthenticated);

export const isSuper = () => (isAuthentificated() && user.Id === 'admin');

export const hasAccess = (key: string) => isSuper() || !!user.Permissions?.includes(key);

export const isInit = () => init;

export const fetchUser = () => Proxy.get('CurrentUser')
    .then(x => {
        if (x.Success) {
            init = true;

            user = x.Result;

            fireUserUpdate();
            /**
            if (isAuthentificated()) {
                let t = getTenant();
                if (!t || !user.tenants.find(x => x.id == t!.id)) {
                    let accId = resolveTenant();
                    if (accId) {
                        setTenantId(accId) || setTenantId();
                    } else {
                        setTenant(user.tenants[0]);
                    }
                }
            }
            /**/
        }

        return user;
    });

export const generateToken = () => Proxy.get('GenerarateAuthToken');

export const setTenantId = function (id?: number) {
    var acc = id ? getUser().Tenants.find(x => x.Id == id) : null;
    if (!!acc == !!id) {
        setTenant(acc);
        return true;
    }
    else {
        return false;
    }
}

export const listenUser = (fn: Function): (() => void) => observer.listen('update', fn);

export const login = (type: string, config: any) => Proxy.post('login' + type, config)
    .then(x => {
        if (x.Success && x.Result.Succeeded) {
            return fetchUser();
        }

        return x;
    });

export const register = (email: string, password: string, confirmPassword: string) =>
    Proxy.post('register', {
        email,
        password,
        confirmPassword
    })
        .then(x => {
            if (x.Success && x.Result.Succeeded) {
                return fetchUser();
            }

            return x;
        });

export const logout = () => {
    return Proxy.post('logout')
        .then(x => {
            fetchUser();
            return x;
        });
}

fetchUser();