import { useRemoteData } from "../Hooks";
import { Metadata } from "../Types";
import { saveMeta } from "../meta/MetaStore";

type StepParam = {
    Name: string,
    Type: string
};

type StepMetadata = {
    Inputs: StepParam[]
    Outputs: StepParam[]
} & Metadata;

var metas: StepMetadata[];

export function getETLSteps() {
    return metas;
}
export function getETLStepByTypeId(typeId:string) {
    return metas.find(x => x.TypeId === typeId);
}

export function useETLSteps() {
    return useRemoteData<StepMetadata[]>('EtlSteplist', x => {
        metas = x;
        metas.forEach(x => {
            x.TypeId = 'etlstep.' + x.TypeId;
            saveMeta(x);
        });
        return x;
    }, null, metas)[0];
}