import { Box, FormControlLabel, List, ListItemButton, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { ReactNode, useState } from 'react';
import { DataPaper } from '../../components/DataView';
import CheckBoxField from '../../components/fields/CheckBoxField';
import DataForm, { FormProxy } from '../../components/form/DataForm';
import { Settings } from '../../model/Types';
import { themes } from '../../theme';

function makeProxy(type: string) {
    return {
        get: id => 'Settings/Get/',
        save: e => 'Settings/Save?type=' + type
    } as FormProxy<Settings>;
}

const mainProxy = makeProxy("Main");
const themeProxy = makeProxy("Theme");
const securityProxy = makeProxy("Security");

function MainSettings() {
    return <DataForm<Settings> id={0} toolbar={{ title: 'Основное' }} responseFormat="controller" proxy={mainProxy}>
        {entity => <Box py={2} gap={2} flex={1} display="flex" flexDirection="column">
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} gap={2}>
                <TextField label="Строка соединения с базой данных" name="dbConnectionString" defaultValue={entity?.DbConnectionString} onChange={e => entity && (entity.DbConnectionString = e.target.value)} fullWidth />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} gap={2}>
                <TextField label="Домен для HTTP-запросов" name="httpRoot" defaultValue={entity?.HttpRoot} onChange={e => entity && (entity.HttpRoot = e.target.value)} fullWidth />
                <TextField label="Домен для HTTPS-запросов" name="httpsRoot" defaultValue={entity?.HttpsRoot} onChange={e => entity && (entity.HttpsRoot = e.target.value)} fullWidth />
            </Box>
        </Box>}
    </DataForm>;
}

function ThemeSettings() {
    return <DataForm<Settings> id={0} toolbar={{ title: 'Тема оформления' }} responseFormat="controller" proxy = { themeProxy } >
        {entity => <Box sx={{ width: '100%', display: 'flex' }} gap={2}>
            <Select label="Тема по умолчанию" name="defaultTheme" fullWidth defaultValue={entity?.DefaultTheme} onChange={e => entity && (entity.DefaultTheme = e.target.value)}>
                {themes?.map(x => <MenuItem value={x.id}>{x.name}</MenuItem>)}
            </Select>
            <FormControlLabel
                control={<CheckBoxField defaultChecked={entity?.AllowChangeTheme} onChange={e => entity && (entity.AllowChangeTheme = e.target.checked)} />}
                label="Показывать значок темы"
                sx={{ whiteSpace: 'nowrap' }}
            />
        </Box>}
    </DataForm>;
}

function SecutirySettings() {
    return <DataForm<Settings> id={0} toolbar={{ title: 'Безопасность' }} responseFormat="controller" proxy={securityProxy}>
        {entity => <Box sx={{ width: '100%', display: 'flex' }} gap={2}>
            <TextField label="Время жизни токена авторизации в минутах"
                name="authExpireTime"
                defaultValue={entity?.AuthExpireTime}
                type="number"
                onChange={e => entity && (entity.AuthExpireTime = parseInt(e.target.value) || undefined)} fullWidth />
        </Box>}
    </DataForm>;
}

const menu: [ReactNode, () => ReactNode][] = [
    ['Основное', () => <MainSettings />],
    ['Тема', () => <ThemeSettings />],
    ['Безопасность', () => <SecutirySettings />]
];

export default function SettingsForm() {
    const [sel, setSel] = useState(0);

    return <DataPaper>
        <Box key="menu" style={{ display: 'initial' }} className="datatable-wrapper">
            <List disablePadding>
                {menu.map((x, i) => <ListItemButton key={i} dense selected={i === sel} onClick={() => setSel(i)}>
                    <ListItemText primary={x[0]} sx={{ p: 1 }} />
                </ListItemButton>)}
            </List>
        </Box>
        <Box gap={2} display="flex" flexDirection="column" style={{ flex: 1, overflow: 'auto' }}>
            {menu.map((x, i) => <Box sx={{ flex: 'none' }}>{x[1]()}</Box>)}
        </Box>
    </DataPaper>;
}