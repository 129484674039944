import { EditRounded } from '@mui/icons-material';
import { BoxProps, Button, PaperProps } from '@mui/material';
import route from '../../Router';
import { ResponseFormat } from '../../model/Hooks';
import { IEntity, Metadata } from '../../model/Types';
import { getMetaByTypeId } from '../../model/meta/MetaStore';
import RemoteDataTable, { TableColumn, TableProps } from '../table/DataTable';
import { tableFormTypes } from './fields';
import { defaultColumn } from './fields/Table';
import { MetaField } from '../../model/meta/Field';

interface RemoteEntityTableProps<T> extends TableProps<T> {
    typeId: string;

    columns?: TableColumn<T>[];
    boxProps?: BoxProps
    paperProps?: PaperProps

    url?: string;
    deleteUrl?: string;
    responseFormat?: ResponseFormat
}

export function buildColumnsByMetaFields(fields: MetaField[], meta: Metadata) {
    return fields.map(x => {
        var c = {
            field: x,
            meta: meta
        };
        return {
            ...(tableFormTypes[x['$type'].split('.').filter((_, i) => i).join('.')] || defaultColumn)(c),
            ...c
        }
    });
}

export default function RemoteEntityTable<T extends IEntity<TKey>, TKey = number>({ url, columns, ...props }: RemoteEntityTableProps<T>) {
    var meta = getMetaByTypeId(props.typeId)!;
    if (!columns) {
        columns = buildColumnsByMetaFields(meta.Fields, meta);
    }

    return <RemoteDataTable<T, TKey> {...props}
        toolbar={{
            title: <>
                {meta?.Name}
                <Button color="info" onClick={e => {
                    e.preventDefault();
                    route.setPath('/metadata/' + meta.Id)
                }} startIcon={<EditRounded />}>�������������</Button>
            </>
        }}
        url={url || (props.typeId + 'GetAll')}
        columns={columns}
    />;
}