import ReactDOM from 'react-dom/client';
import App from './App';
import './Startup';
import './index.css';
// roboto ->>
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const root = ReactDOM.createRoot(
    document.getElementById('emdroot') as HTMLElement
);
root.render(<App />);