import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { ReactDashboard } from '../../model/Types';
import dashboardsIcon from '../../theme/mpt/dashboardsIcon';

const headCells: TableColumn<ReactDashboard>[] = [
    {
        id: 'Name',
        label: 'Наименование',
    }/*,
    {
        id: 'templateId',
        label: 'Шаблон'
    }*/
];

export default function DashboardsTable({ ...props }: TableProps<ReactDashboard>) {
    return <DataTable<ReactDashboard> toolbar={{
        title: 'Дашборды',
        icon: <div style={{ zoom: .5, display: 'flex' }}>{dashboardsIcon}</div>
    }} typeId="ReactDashboard"
        columns={headCells}
        url="ReactDashboard/List"
        responseFormat="controller"
        groupName="ReactDashboard" {...props} />;
}