import { Group } from '@mui/icons-material';
import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { Role } from '../../model/Types';

const headCells: TableColumn<Role>[] = [
    {
        id: 'Name',
        label: 'Наименование',
    }
];

export default function RolesTable(props: TableProps<Role>) {
    return <DataTable<Role, string>
        toolbar={{ title: 'Роли', icon: <Group /> }}
        columns={headCells}
        typeId="Role"
        groupName="Role"
        url="Role/List"
        responseFormat="controller"
        {...props} />;
}