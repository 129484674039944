import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ChangeEvent, Component, HTMLInputTypeAttribute, ReactNode } from "react";
import { fieldFormTypes } from '.';
import CompositeField, { TableField } from '../../../model/meta/CompositeField';
import { MetaField } from '../../../model/meta/Field';
import CheckBoxField from '../../fields/CheckBoxField';
import { TableColumn } from '../../table/DataTable';
import InlineDataTable from '../../table/InlineDataTable';
import { buildColumnsByMetaFields } from '../EntityTable';
import { FormFieldProps } from './FieldProps';

/**
 * @param V - ��� ������ � �������
 * @param F - ��� ���� � HTML
 */
export abstract class FormField<V = any, F = V> extends Component<FormFieldProps> {
    constructor(p: FormFieldProps) {
        super(p);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e: ChangeEvent) {
        const p = this.props,
            v = this.formValueToField((e.target as any).value as F, e.target);

        if (!p.apiRef || !p.apiRef.onChange || p.apiRef.onChange(p.parentObject[p.field.Code], v, p, e) !== false) {
            p.parentObject[p.field.Code] = v;
        }
    }

    fieldToFormValue(v: V): F | null | undefined {
        return v as any as F;
    }

    formValueToField(v: F, el: Element): V | null | undefined {
        return v as any as V;
    }

    getProps() : any {
        const p = this.props,
            f = p.field;

        return {
            key: f.Code,
            name: f.Code,
            label: f.Name || f.Code,
            defaultValue: p.parentObject && this.fieldToFormValue(p.parentObject[f.Code]),
            onChange: this.onChange,
            hidden: f.Hidden
        };
    }
};

export class SimpleFormField<V> extends FormField<V> {
    protected formFieldType?: HTMLInputTypeAttribute;

    getProps() {
        const res = super.getProps();
        res.type = this.formFieldType;
        return res;
    }

    render() {
        const f = this.props.field,
            avs = f.AvailableValues;
        if (avs) {
            return <FormControl fullWidth>
                <InputLabel id={f.Code + '_label'}>{f.Name || f.Code}</InputLabel>

                <Select key={f.Code} labelId={f.Code + '_label'} {...this.getProps()}>
                    {avs.map(x => <MenuItem value={x.split(':')[0]}>{x.split(':')[1]}</MenuItem>)}
                </Select>
            </FormControl>
        } else {
            return <TextField {...this.getProps()} />;
        }
    }
};

export class NumberFormField extends SimpleFormField<number> {
    constructor(p: FormFieldProps) {
        super(p);
        this.formFieldType = 'number';
    }
}

export class BooleanFormField extends FormField<boolean> {
    protected formFieldType?: HTMLInputTypeAttribute;

    formValueToField(v: boolean, el: Element) {
        return (el as HTMLInputElement).checked;
    }

    render() {
        const p = this.props,
            f = p.field;

        return <FormControlLabel
            key={f.Code}
            control={<CheckBoxField
                edge="start"
                name={f.Code}
                defaultChecked={p.parentObject && this.fieldToFormValue(p.parentObject[f.Code])}
                tabIndex={-1}
                disableRipple
                onChange={this.onChange}
            />}
            label={f.Name}
            sx={{ whiteSpace: 'nowrap' }} />
    }
}

export class DateFormField extends FormField<Date, dayjs.Dayjs> {
    formValueToField(v: dayjs.Dayjs, el: Element) {
        return v?.toDate();
    }

    fieldToFormValue(v: Date) {
        return v ? dayjs(v) : undefined;
    }

    render() {
        const p = this.props,
            f = p.field;

        return <DatePicker
            key={f.Code}
            name={f.Code}
            label={f.Name || f.Code}
            defaultValue={p.parentObject && this.fieldToFormValue(p.parentObject[f.Code])}
            onChange={value => this.onChange({ target: { value } } as any)} />;
    }
}

export class DateTimeFormField extends DateFormField {
    render() {
        const p = this.props,
            f = p.field;

        return <DateTimePicker
            key={f.Code}
            name={f.Code}
            label={f.Name || f.Code}
            defaultValue={p.parentObject && this.fieldToFormValue(p.parentObject[f.Code])}
            onChange={value => this.onChange({ target: { value } } as any)} />;
    }
}

export class CompositeFormField extends FormField<object> {
    protected formFieldType?: HTMLInputTypeAttribute;
    private fieldsMap: {
        field: MetaField,
        formField: (props: FormFieldProps) => ReactNode
    }[]

    constructor(p: FormFieldProps) {
        super(p);

        this.fieldsMap = (p.field as CompositeField).Fields.map(x => {
            const typeId = x['$type'].split('.').filter((_, i) => i).join('.');
            return {
                field: x,
                formField: fieldFormTypes[typeId]
            };
        }).filter(x => x?.formField);
    }

    formValueToField(v: object, el: Element) {
        return v;
    }

    render() {
        const p = this.props,
            po = p.parentObject,
            f = p.field as CompositeField,
            v = po[f.Code] || (po[f.Code] = {});

        const fields = this.fieldsMap.map(x => x.formField({
            field: x.field,
            meta: p.meta,
            entity: p.entity,
            parentObject: v,
            propsApplier: p.propsApplier
        }));

        return <Box display="flex" flexDirection="column" gap={2} pl={2}>
            <Typography variant="h6">{f.Name}</Typography>
            {fields}
        </Box>
    }
}

export class TableFormField extends FormField<object[]> {
    protected formFieldType?: HTMLInputTypeAttribute;
    private columns: TableColumn<any>[]

    constructor(p: FormFieldProps) {
        super(p);

        const cols= buildColumnsByMetaFields((p.field as TableField).Fields, p.meta);
        cols.forEach(x => {
            const typeId = x.field['$type'].split('.').filter((_, i) => i).join('.');
            const formField = fieldFormTypes[typeId];
            x.renderer = (d: any) => formField({
                field: x.field,
                meta: p.meta,
                entity: d,
                parentObject: d,
                propsApplier: p.propsApplier
            });
        });
        this.columns = cols;
    }

    formValueToField(v: object[], el: Element) {
        return v;
    }

    render() {
        const p = this.props,
            po = p.parentObject,
            f = p.field as TableField,
            v = po[f.Code] || (po[f.Code] = []);

        const props = this.props.propsApplier({
            toolbar: { title: f.Name },
            columns: this.columns,
            data: v
        });

        return <InlineDataTable {...props} />;
    }
}