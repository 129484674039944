import { Box, BoxProps } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { EntityApi } from './form/DataForm';
import { useRoute } from '../model/Hooks';
import './dataview.css';

interface DataViewProps<TKey, TEntity> {
    table: (onSelectRow?: (event: React.MouseEvent<unknown>, data: TEntity, index: number) => void) => ReactNode
    form: (id: TKey, apiRef?: (formApi: EntityApi<TEntity, TKey>) => any) => ReactNode,
    paper?: BoxProps
}

export function DataPaper(props: BoxProps) {
    return <Box className="data-view" display="flex" {...props} />;
}

export default function DataView<TKey = number, TEntity = any>({ table, form, paper, ...props }: DataViewProps<TKey, TEntity>) {
    const [route] = useRoute();
    const [formApi, setFormApi] = useState<EntityApi<TEntity, TKey>>();
    const [selected, setSelected] = useState<TKey>(() => route.get<TKey>('id'));

    useEffect(() => route.listen(r => changeSelected(route.get<TKey>('id'))));

    function changeSelected(id: TKey) {
        if (id != selected) {
            formApi?.reload(id);
            setSelected(id);
        }
    }

    const onSelectRow = useMemo(() => {
        return (event: React.MouseEvent<unknown>, data: TEntity, index: number) => changeSelected((data as any).Id || (data as any).id);
    }, [formApi]);

    return <DataPaper {...paper}>
        {table(onSelectRow)}
        {selected ? form(selected === 'new' ? null as TKey : selected, setFormApi) : null}
    </DataPaper>
}