import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { SqlDataSource } from '../../model/Types';
import datasourcesIcon from '../../theme/mpt/datasourcesIcon';

export const dataSourcesTableCells: TableColumn<SqlDataSource>[] = [
    {
        id: 'Name',
        label: 'Наименование',
    }
];

export default function DataSourcesTable(props: TableProps<SqlDataSource>) {
    return <DataTable<SqlDataSource>
        toolbar={{ title: 'Источники', icon: <div style={{ zoom: .5, display: 'flex' }} >{datasourcesIcon}</div> }}
        columns={dataSourcesTableCells}
        url="SqlDataSource/SimpleList"
        responseFormat="controller"
        typeId="SqlDataSource"
        groupName="SqlDataSource"
        {...props} />;
}