import { OpenInNew } from '@mui/icons-material';
import { Chip, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { root, rootHttp } from '../model/Proxy';

interface UrlProps {
    type: string,
    guid: string,
    path: string,
    https?: boolean
}

export function getUrl(type: string, guid: string, path: string, https: boolean) {
    return ((https ? root : rootHttp) || (document.location.host + '/')) + 'cdn/' + guid + '/' + (https ? '' : 'public/') + path + '.' + type;
}

export default function UrlField({ type, guid, path, https }: UrlProps) {
    https = https || (https !== undefined);
    const value = getUrl(type, guid, path, https);

    return <TextField
        label={type.toUpperCase() + ' URL (HTTP' + (https ? 'S' : '') + ')'}
        name="guid"
        value={value}
        fullWidth
        InputProps={{
            endAdornment:
                <InputAdornment position="end">
                    <Link color="inherit" href={value} target="_blank">
                        <OpenInNew />
                    </Link>
                </InputAdornment>
        }}
    />;
}

export function HtmlJsChips({ label, guid, path }: { label?: string, guid: string, path: string }) {
    return <Stack className="url-field" direction="row" spacing={1}>
        {label ? <Typography variant="h6" m={3} mb={0}>{label}:</Typography> : null}
        <Chip label="HTTPS HTML" component="a" target="_blank" href={getUrl('html', guid, path, true)} clickable />
        <Chip label="HTTPS JS" component="a" target="_blank" href={getUrl('js', guid, path, true)} clickable />
        <Chip label="HTTP HTML" component="a" target="_blank" href={getUrl('html', guid, path, false)} clickable />
        <Chip label="HTTP JS" component="a" target="_blank" href={getUrl('js', guid, path, false)} clickable />
    </Stack>;
}