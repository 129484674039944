import { CheckRounded, HorizontalRuleRounded } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, SxProps, Theme, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material';
import { FormEvent, MutableRefObject, ReactNode, useMemo, useRef, useState } from 'react';
import { ResponseFormat, useRemoteData, useRoute } from '../../model/Hooks';
import Proxy from '../../model/Proxy';
import { IEntity, IdName } from '../../model/Types';
import './datataform.css';

interface DataFormToolbarProps<T> {
    title?: ReactNode
    actions?: (tp: DataFormToolbarProps<T>, entity?: T) => ReactNode;
    prepend?: (tp: DataFormToolbarProps<T>) => ReactNode;
    append?: (tp: DataFormToolbarProps<T>) => ReactNode;
    actionsSx?: SxProps<Theme>
}

export interface FormProxy<T, TKey = number> {
    get: (id: TKey) => string,
    save?: (entity: T) => string,
    delete?: (entity: T) => string,
}

export interface FormProps<T> {
    onGet?: (entity: T) => T
    onSubmit?: (entity: T) => T | null
    onSaved?: (entity: T) => any
    onDeleted?: (entity: T) => any
    responseFormat?: ResponseFormat
    data?: T,
    toolbar?: DataFormToolbarProps<T>
    allowSave?: boolean
}

export interface EntityApi<T, TKey = number> {
    reload: (id?: TKey) => Promise<T>
    delete: () => Promise<boolean> | null
    save: () => void
    entityRef: MutableRefObject<T | null>
}

export interface IdFormProps<T, TKey = number> extends FormProps<T> {
    id: TKey
}

export interface BaseDataFormProps<T, TKey = number> extends IdFormProps<T, TKey> {
    proxy: FormProxy<T, TKey>
    sx?: {
        form?: SxProps<Theme>,
        body?: SxProps<Theme>
    }
}

export interface DataFormProps<T, TKey = number> extends BaseDataFormProps<T, TKey> {
    children: (entity: T, api: EntityApi<T, TKey>) => ReactNode
}

export default function DataForm<T extends IEntity<TKey>, TKey = number>({ proxy, children, ...props }: DataFormProps<T, TKey>) {
    const [id, setId] = useState(props.id);
    const [error, setError] = useState<string>();
    const [route] = useRoute();

    const [entity, reload] = useRemoteData<T>(proxy.get(id), props.onGet, props.responseFormat, props.data);
    const entityRef = useRef(entity);
    entityRef.current = entity;

    const api = useMemo<EntityApi<T, TKey>>(() => {
        return {
            reload: (id?: TKey) => {
                if (id) {
                    return reload(proxy.get(id)).then(x => {
                        setId(id);
                        return x;
                    });
                } else {
                    return reload();
                }
            },
            delete: () => {
                if (entityRef.current && proxy.delete) {
                    return Proxy.post(proxy.delete(entityRef.current), entityRef.current.Id)
                        .then(x => {
                            if (x && props.onDeleted) {
                                props.onDeleted(entityRef.current!);
                            }

                            return !!x;
                        });
                }

                return null;
            },
            save: onSubmit,
            entityRef
        }
    }, []);

    function onSubmit(e?: FormEvent<HTMLFormElement>) {
        e?.preventDefault();
        if (entity) {
            const entityToSave = props.onSubmit ? props.onSubmit(entity) : entity;
            if (!entityToSave) {
                return;
            }

            const path = route.get<IdName[]>('path');
            if (path?.length) {
                (entityToSave as any).GroupId = path[path.length - 1].Id;
            }

            if (route.get('copy')) {
                delete (entityToSave as any).Id;
            }

            if (proxy.save) {
                Proxy.post(proxy.save(entityToSave), entityToSave).then(e => {
                    switch (props.responseFormat) {
                        case 'controller': break;
                        default: e = e.result; break;
                    }

                    if (typeof e === typeof 1) {
                        entity.Id = e as any as TKey;
                    } else {
                        Object.assign(entity, e);
                    }

                    props.onSaved && props.onSaved(entity);
                    setId(entity.Id as TKey);
                    if (route.get('copy')) {
                        route.setState('copy', false);
                    }
                }, e => setError(e?.message));
            }
        }
    }

    if (!entity) {
        return <></>;
    }

    var actions = props.toolbar?.actions && props.toolbar?.actions(props.toolbar, entity);

    return <Paper className="dataform-wrapper" component="form" elevation={0} sx={props?.sx?.form ? Object.assign({}, styles.form, props?.sx?.form) : styles.form} onSubmit={onSubmit}>
        <Toolbar sx={{ gap: 1 }}>
            <Typography variant="h6" className="title">
                {props.toolbar?.title || 'Параметры'}{route.get('copy') ? ' [Копия]' : ''}
            </Typography>

            <ButtonGroup variant="outlined" sx={{ mx: 'auto', ...props.toolbar?.actionsSx }}>{actions}</ButtonGroup>

            {props.toolbar?.prepend && props.toolbar?.prepend(props.toolbar)}
            {!!proxy.save && props.allowSave !== false ? <Button startIcon={<CheckRounded />} variant="contained" color="success" type="submit">Сохранить</Button> : null}

            {(props.id && proxy.delete && !route.get('copy')) ?
                <Button startIcon={<HorizontalRuleRounded />} variant="contained" color="error" onClick={api.delete}>Удалить</Button> :
                null}

            {props.toolbar?.append && props.toolbar?.append(props.toolbar)}
        </Toolbar>

        <Box key={'body-' + id} className="dataform-body" sx={props?.sx?.body ? Object.assign({}, styles.body, props?.sx?.body) : styles.body}>
        {children(entity, api)}
        </Box>

        <Dialog open={!!error} onClose={() => setError(undefined)}>
            <DialogTitle>Ошибка при сохранении</DialogTitle>
            <DialogContent>
                <DialogContentText>{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setError(undefined)}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    </Paper>
}

export interface DataTabFormProps<T, TKey = number> extends BaseDataFormProps<T, TKey> {
    tabs: {
        text: ReactNode
    }[]
    children: ((entity: T, api: EntityApi<T, TKey>) => ReactNode)[]
}
export function DataTabForm<T extends IEntity<TKey>, TKey = number>({ children, toolbar, tabs, ...props }: DataTabFormProps<T, TKey>) {
    const [tab, setTab] = useState(0);

    return <DataForm<T, TKey> toolbar={{
        ...toolbar,
        title: <>
            {toolbar?.title}
            <ToggleButtonGroup size="small" exclusive value={tab} onChange={(e, i) => setTab(i)} className="data-tab-form-tabs">
                {tabs.map((x, i) => <ToggleButton value={i}>{x.text}</ToggleButton>)}
            </ToggleButtonGroup>
        </>
    }} {...props} >
        {children[tab]}
    </DataForm>;
}

const styles: { [name: string]: SxProps<Theme> } = {
    form: {
        border: 'none',
        width: '100%',
        //minHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    body: {
        border: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        px: 3
    }
}