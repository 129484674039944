import { Box, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ReactNode } from 'react';
import route from '../Router';
import { IdName } from '../model/Types';
import { IAppMenuItem, getMenuItemPath, setMenuItem } from './AppRoute';
import ChangeLocation from '../components/Location';
import './nav.css';

export default function Breadcrumbs({ start }: { start: IAppMenuItem }) {
    const breadcrumbs: ReactNode[] = [];
    const menuItemPath = getMenuItemPath(start);

    var path: IdName[] = [];
    var routePath = route.get<IdName[]>('path');
    routePath?.forEach((x, i) => {
        path.push(x);
        const p = path.slice();

        breadcrumbs.push(
            <Link key={'gr' + x}
                color="inherit"
                underline="hover"
                href={menuItemPath + '?path=' + path.map(x => x.Id + '_' + x.Name).join('/')}
                onClick={e => {
                    route.setState([['path', p], ['id']]);
                    e.preventDefault();
                }}>
                {x.Name}
            </Link>);
    });

    var id = route.get<number>('id');
    if (id) {
        breadcrumbs.push(<Typography key={'id'}>
            {id}
        </Typography>);
    }

    var changeLocation: ReactNode;
    if (breadcrumbs.length && start.typeId) {
        changeLocation = <ChangeLocation menuItem={start} id={id/** || routePath[routePath.length - 1].id/**/} />;
    }


    return <Box gap={1} display="flex" flexDirection="row" alignItems="center" className="location-breadcrumbs">
        <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
            {(start.breadcrumbs && start.breadcrumbs()?.map((x, i) => <Link underline="hover" key={'starts' + i} color="inherit" onClick={e => {
                x.handler();
                e.preventDefault();
            }}>
                {x.title}
            </Link>)) || <Link underline="hover" key="1" color="inherit" href={menuItemPath} onClick={e => {
                setMenuItem(start);
                e.preventDefault();
            }}>
                    {start.title}
                </Link>}

            {breadcrumbs}
        </MuiBreadcrumbs>
        {changeLocation}
    </Box>;
}