import { CheckRounded, ClearRounded, CopyAll, Loop } from '@mui/icons-material';
import { Box, Button, FormControlLabel, FormLabel, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { DataPaper } from '../components/DataView';
import DataForm, { FormProxy } from '../components/form/DataForm';
import Proxy from '../model/Proxy';
import { License } from '../model/Types';
import { menu } from '../nav/AppRoute';
import CheckBoxField from '../components/fields/CheckBoxField';

const licenseProxy: FormProxy<License> = {
    get: id => 'License/Get',
    save: e => 'License/Save'
}

function ObjectsCountLimit({ license }: { license: License }) {
    const objectsCountLimit: [string, number | undefined][] = [];
    for (var typeId in license.ObjectsCountLimit) {
        objectsCountLimit.push([
            typeId === 'Devs' ? 'Разработчики' : (menu.find(x => x?.typeId === typeId)?.title || typeId) as string,
            license.ObjectsCountLimit[typeId]
        ]);
    }

    return <>{objectsCountLimit.map(x => <Typography variant="body1">{x[0]}: {x[1]}</Typography>)}
    </>;
}

const products = [
    ['data', 'Данные'],
    ['graph', 'Графика']
];

function Products({ license }: { license: License }) {
    if (!license.Products?.length) {
        return <Typography variant="body1">Без ограничений</Typography>
    }

    return <>{products.map(p => <FormControlLabel
        key={p[0]}
        control={<CheckBoxField
            edge="start"
            name={p[0]}
            checked={!!license.Products?.find(x => x === p[0])}
            tabIndex={-1}
            disableRipple
        />}
        label={p[1]}
        sx={{ whiteSpace: 'nowrap' }} />)}</>;
}

export default function LicenseForm() {
    const [step, setStep] = useState(0);
    const [machineKey, setMachineKey] = useState('');
    const [license, setLicense] = useState('');

    return <DataPaper>
        <DataForm<License> id={0} key={step} allowSave={false} responseFormat="controller" toolbar={{
            title: 'Общая информация',
            append: tp => step ? null : <Button variant="contained" color="success" startIcon={<Loop />} onClick={() => {
                setStep(step + 1);
                Proxy.getText('License/GetActivationCode').then(x => setMachineKey(x), () => setStep(0));
            }}>Обновить лицензию</Button>
        }} proxy={licenseProxy}>
            {(entity, api) => <>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} gap={3}>
                    <TextField label="Актуальна до" value={moment(entity?.DateExpires)?.format('DD.MM.yyyy')} />
                    <TextField label="Номер лицензии" value={entity.Serial} fullWidth />
                </Box>

                <Typography variant="h5">Параметры лицензии</Typography>

                <Box className="license-params" display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h6">Доступные продукты</Typography>

                    <Box display="flex" gap={1} flexDirection="column">
                        <Products license={entity} />
                    </Box>
                </Box>

                <Box className="license-params" display="flex" flexDirection="column" gap={1}>
                    <Typography variant="h6">Ограничение на количество создаваемых объектов</Typography>

                    <Box display="flex" gap={1} flexDirection="column">
                        <ObjectsCountLimit license={entity} />
                    </Box>
                </Box>

                {step ? <Box display="flex" gap={1} flexDirection="row">
                    <Typography variant="h6">Обновление лицензии</Typography>
                    <Button variant="contained" startIcon={<CheckRounded />} color="success" disabled={!license} onClick={() => {
                        setStep(step + 1);
                        Proxy.post('License/Save', license).then(x => {
                            Object.assign(entity, x);
                            setStep(0);
                        }, () => setStep(step - 1));
                    }}>Применить лицензию</Button>
                    <Button variant="contained" startIcon={<ClearRounded />} color="warning" onClick={() => setStep(0)}>Отмена</Button>
                </Box> : null}
                {step === 1 && machineKey ? <>
                    <OutlinedInput label="Код экземпляра приложения" value={machineKey} endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={() => navigator.clipboard.writeText(machineKey)} edge="end"><CopyAll /></IconButton>
                        </InputAdornment>} />

                    <TextField multiline label="Код активации" name="license" value={license} onChange={e => setLicense(e.target.value)} />
                </> : null}
            </>}
        </DataForm>
    </DataPaper>;
}