import Observer from "../Observer";
import Field from "./Field";

export default class DataField<T = any, TField extends Field<T> = Field<T>> extends Observer {
    private _field!: TField
    private _value?: T

    constructor(field: TField) {
        super();

        this._field = field;
        this._value = field.defaultValue;
    }

    public get name() {
        return this._field.name;
    }

    public get value() {
        return this._value;
    }

    public set value(v: T | undefined) {
        this._value = v;
    }
};