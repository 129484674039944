import Proxy from "../Proxy";
import { SqlDataSource } from "../Types";
import Datasource, { DSConfig } from "./Datasource";

export type SqlDSConfig = {
} & DSConfig;

export default class SqlDatasource<TData = any> extends Datasource<SqlDataSource, TData> {
    load(): Promise<TData[]> {
        var pars: any = {};
        this._params?.forEach(x => pars[x.name] = x.value);

        return Proxy.post('SqlProxy/Execute'/** + (readOnly ? 'Dashboard' : '')/**/ + 'DataSource', {
            id: this._config.Id,
            sqlText: this._config.SqlText,
            parameters: pars
        });
    }
}