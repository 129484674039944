import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { Metadata } from '../../model/Types';
import datasourcesIcon from '../../theme/mpt/datasourcesIcon';

const headCells: TableColumn<Metadata>[] = [
    {
        id: 'Name',
        label: 'Наименование',
        renderer: x => x.Name + ' (' + x.TypeId + ')'
    }
];

export default function MetadataTable(props: TableProps<Metadata>) {
    return <DataTable
        toolbar={{
            title: 'Хранилища',
            icon: <div style={{ zoom: .5, display: 'flex' }} >{datasourcesIcon}</div>
        }}
        columns={headCells}
        url="MetadataList"
        typeId="Metadata"
        groupName="Metadata"
        {...props} />;
}