import { Parameter } from "../Types";
import Field from "./Field";

export default Field;

const fieldTypes = {
    Number: () => new NumberField(),
    String: () => new Field<string>(),
    Date: () => new DateField(),
    Boolean: () => new BoolField(),
}

export function fieldFromParameter(p: Parameter) {
    var f = (fieldTypes[p.Type] || fieldTypes.String)();

    f.name = p.Name;
    f.title = p.Title;
    f.defaultValue = p[p.Type];

    return f;
}

export class NumberField extends Field<number> {
};

export class DateField extends Field<Date> {
};

export class BoolField extends Field<boolean> {
};