import route from '../../Router';
import DataView from '../../components/DataView';
import EntityForm, { Entity } from '../../components/entity/EntityForm';
import RemoteEntityTable from '../../components/entity/EntityTable';
import { useRoute } from '../../model/Hooks';
import { setEntityView } from '../../model/meta/MetaStore';

setEntityView(meta => <EntityView typeId={meta.TypeId} />);

route.register('typeId');

route.addRoute('data/dataview')
    .setDefault('app', () => 'data')
    .addUrlPath('typeId')
    .addUrlPath('id')
    .addUrlPath('tab');

export default function EntityView({ typeId }: { typeId: string }) {
    const [route] = useRoute();

    return <DataView<number, Entity>
        table={(onSelectRow) => <RemoteEntityTable typeId={typeId} onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <EntityForm typeId={typeId} id={id} onSaved={e => route.setState('id', e.Id)} onDeleted={e => route.setState([['id']])} apiRef={apiRef} />}
    />;
}