import RdbmsStorageInfo from "./RdbmsStorageInfo";
import StorageInfo from "./StorageInfo";
import RdbmsStorageForm from "../../components/storages/RdbmsStorageForm";

export const defaultStorageType = RdbmsStorageInfo.TypeId;

export const storageTypes = [
    {
        TypeId: RdbmsStorageInfo.TypeId,
        name: 'Реляционная СУБД',
        create: () => new RdbmsStorageInfo(),
        component: (value: StorageInfo, onChange: (v: RdbmsStorageInfo) => any) => <RdbmsStorageForm value={value} onChange={onChange} />
    }
];