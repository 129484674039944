import { ChevronRight } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControl, FormControlLabel, InputLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { ReactNode, useState } from 'react';
import { DataSourceCombo } from '.';
import route from '../../Router';
import { ParametersForm } from '../../components/ParametersForm';
import Textarea from '../../components/Textarea';
import CheckBoxField from '../../components/fields/CheckBoxField';
import DataForm, { EntityApi, FormProps, FormProxy } from '../../components/form/DataForm';
import { ConnectionType } from '../../model/Enums';
import { Connection, Parameter, SqlDataSource } from '../../model/Types';
import SqlDatasource from '../../model/ds/SqlDatasource';
import { isObject } from '../../utils';
import { parseCurl } from '../../utils/parseCurl';
import { ConnectionCombo } from '../connections';

const proxyGet: FormProxy<SqlDataSource> = {
    get: id => 'SqlDataSource/Get/' + id
}

const proxy: FormProxy<SqlDataSource> = Object.assign({
    save: e => 'SqlDataSource/Save',
    delete: e => 'SqlDataSource/Delete'
} as FormProxy<SqlDataSource>, proxyGet);

function buildCell(v: any, row: any, col: any) {
    if (Array.isArray(v) || isObject(v)) {
        v = JSON.stringify(v);
    }

    return <TableCell key={col.name}>{v}</TableCell>;
}

interface Meta {
    name: string,
    label?: string,
    type: string
};
interface DataMeta {
    data: any,
    meta: Meta[]
};

interface CheckDSProps {
    entity: SqlDataSource
    parameters: Parameter[]
    connection?: Connection
    readOnly?: boolean
    onDataLoaded?: (data: DataMeta) => any
}

function CheckDS({ entity, parameters, connection, readOnly, onDataLoaded }: CheckDSProps) {
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(false);

    var checkDS: {
        check: () => Promise<any>,
        render: (data: any) => ReactNode | undefined
    };

    switch (connection?.Type) {
        case ConnectionType.RTA:
            checkDS = {
                check: () => {
                    var token = connection.Config?.externalTokenDebug || connection.Config?.externalToken;

                    var text = entity.SqlText;
                    text = entity.Parameters?.reduce(
                        (result, par) => result.replace(`$\{${par.Name}}`, `${par[par.Type]}`),
                        text,
                    );

                    return fetch(connection.ConnectionString + '/SqlProxy/Execute' + (token ? '?externalToken=' + token : ''), {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        "body": JSON.stringify({
                            Connection: entity.DsConnectionId ? parseInt(entity.DsConnectionId) : undefined,
                            Sql: text
                        }),
                        "method": "POST",
                        "mode": "cors"
                    }).then(x => x.json()).then(x => {
                        const dm = {
                            data: x.Data,
                            meta: x.Meta.map((x: any) => {
                                return {
                                    name: x.Code,
                                    label: x.Caption,
                                    type: x.Type
                                };
                            })
                        };

                        onDataLoaded && onDataLoaded(dm);
                        setData(dm);
                    });
                },
                render: (data) => {
                    if (data.meta) {
                        return <Table>
                            <TableHead>
                                <TableRow>
                                    {data.meta.map((x: any) => <TableCell key={x.name}>{x.label}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.data.map((row: any) => <TableRow>
                                    {data.meta.map((x: any) => buildCell(row[x.name], row, x))}
                                </TableRow>)}
                            </TableBody>
                        </Table>;
                    } else {
                        return JSON.stringify(data);
                    }
                }
            }
            break;

        case ConnectionType.Url:
            checkDS = {
                check: () => {
                    var requestInfo = parseCurl(entity.SqlText);
                    return fetch(requestInfo.url, requestInfo).then(x => x.json()).then(x => setData(x));
                },
                render: d => d
            };
            break;

        case ConnectionType.Postgres:
        case ConnectionType.MsSql:
        case ConnectionType.MySql:
        case ConnectionType.Oracle:
        case ConnectionType.DB:
            checkDS = {
                check: () => new SqlDatasource(entity).load().then((x: any) => {
                    onDataLoaded && onDataLoaded(x);
                    setData(x);
                }),
                render: (data) => {
                    if (data.meta) {
                        return <Table>
                            <TableHead>
                                <TableRow>
                                    {data.meta.map((x: any) => <TableCell key={x.name}>{x.name}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.data.map((row: any) => <TableRow>
                                    {data.meta.map((x: any) => buildCell(row[x.name], row, x))}
                                </TableRow>)}
                            </TableBody>
                        </Table>;
                    } else {
                        return JSON.stringify(data);
                    }
                }
            }
            break;

        default: return <></>;
    }

    return <Box pt={2} className="check-ds-wrapper">
        <Button variant="contained"
            disabled={loading}
            onClick={() => {
                checkDS?.check().finally(() => setLoading(false));
                setLoading(true)
            }}>Проверить результат</Button>
        {data ? <div className="check-ds-body">{checkDS?.render(data)}</div> : null}
    </Box>;
}

export function DataSourceFormFields({ entity, parameters, readOnly, api }: { entity: SqlDataSource, api?: EntityApi<SqlDataSource>, parameters: Parameter[], readOnly?: boolean }) {
    const [cs, setCs] = useState<Connection[]>();
    const [preview, setPreview] = useState<DataMeta>();

    return <>
        <Box sx={styles.fieldGroup} gap={2}>
            <TextField label="Наименование" name="name" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth required />
            {readOnly ? null : <FormControlLabel
                control={<CheckBoxField defaultChecked={entity?.Shared} onChange={e => entity && (entity.Shared = e.target.checked)} />}
                label="Общий доступ"
                sx={{ whiteSpace: 'nowrap' }} />}
        </Box>

        {!readOnly ?
            null :
            <FormControl sx={styles.fieldGroup}>
                <InputLabel id="templateId">Источник данных</InputLabel>
                <DataSourceCombo name="templateId" defaultValue={entity.SqlDataSourceId} required={false} onChange={e => entity && (entity.SqlDataSourceId = parseInt(e.target.value as string))} fullWidth />
            </FormControl>
        }

        <Box sx={styles.fieldGroup} gap={1}>
            <ConnectionCombo defaultValue={entity.ConnectionId} onChange={e => entity && (entity.ConnectionId = parseInt(e.target.value as string))} onDataLoad={d => { setCs(d); return d }} />
            <ChevronRight color="disabled" />
            <TextField label="Идентификатор соединения с источником данных"
                name="dsConnectionId"
                defaultValue={entity?.DsConnectionId}
                onChange={e => entity && (entity.DsConnectionId = e.target.value)}
                fullWidth />
        </Box>

        <Box gap={1}>
            <Textarea name="sqlText"
                defaultValue={entity?.SqlText}
                onChange={e => entity && (entity.SqlText = e.target.value as string)}
                minRows={6}
                sx={{ width: '100%', borderRadius: 2 }}
                required={!readOnly}
                placeholder="Текст запроса" />
        </Box>

        <Box display="flex" gap={1}>
            <Autocomplete key="key"
                disablePortal
                options={preview?.meta?.map(x => x.name) || []}
                freeSolo
                sx={{ flex: 1 }}
                selectOnFocus
                blurOnSelect
                onChange={(e, v) => entity && (entity.KeyField = v || undefined)}
                defaultValue={entity?.KeyField}
                renderInput={(params) => <TextField {...params}
                    label="Ключевое поле"
                    name="KeyField"
                    onChange={e => entity && (entity.KeyField = e.target.value || undefined)} />}
            />
            <Autocomplete key="name"
                disablePortal
                options={preview?.meta?.map(x => x.name) || []}
                freeSolo
                sx={{ flex: 1 }}
                selectOnFocus
                blurOnSelect
                onChange={(e, v) => entity && (entity.NameField = v || undefined)}
                defaultValue={entity?.NameField}
                renderInput={(params) => <TextField {...params}
                    label="Поле для Наименования"
                    name="NameField"
                    onChange={e => entity && (entity.NameField = e.target.value || undefined)} />}
            />
        </Box>

        <ParametersForm parameters={parameters} />

        <CheckDS entity={entity} parameters={parameters} connection={cs?.find(x => x.Id === entity.ConnectionId)} readOnly={readOnly} onDataLoaded={setPreview} />
    </>;
}

export default function DataSourceForm({ apiRef, ...props }: { id: number, form?: FormProps<SqlDataSource> } & { apiRef?: (api: EntityApi<SqlDataSource>) => any }) {
    const [parameters, setParameters] = useState<Parameter[]>([]);

    function onGet(x: SqlDataSource) {
        setParameters(x.Parameters || []);
        return x;
    }

    function onSubmit(entity: SqlDataSource) {
        entity.Parameters = parameters;
        return entity;
    }

    function onSaved(entity: SqlDataSource) {
        route.setState('id', entity.Id);
        props.form?.onSaved && props.form.onSaved(entity);
    }

    return <DataForm<SqlDataSource> responseFormat="controller" id={props.id} proxy={proxy} onSubmit={onSubmit} onGet={onGet} onSaved={onSaved}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <DataSourceFormFields entity={entity} api={api} parameters={parameters} />;
        }}
    </DataForm>
}

const styles = {
    fieldGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}