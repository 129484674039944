import { Person } from '@mui/icons-material';
import DataTable, { TableColumn, TableProps } from '../../components/table/DataTable';
import { User } from '../../model/Types';

const headCells: TableColumn<User>[] = [
    {
        id: 'UserName',
        label: 'Логин',
    },
    {
        id: 'Name',
        label: 'ФИО',
    },
    {
        id: 'Roles',
        label: 'Роли',
    }
];

export default function UsersTable(props: TableProps<User>) {
    return <DataTable<User, string> toolbar={{ title: 'Пользователи', icon: <Person /> }} typeId="User" columns={headCells} url="User/List"
        responseFormat="controller"
        groupName="User" {...props} />;
}