export class MetaField {
    $type!: string
    Code!: string
    Name?: string
    Description?: string
    Hidden?: boolean
    Required?: boolean
    AutoGenerated?: boolean
    AvailableValues?: string[]
    Options?: any

    static is(field: MetaField) {
        return 'field.' + (this as any).TypeId === field.$type;
    }
};

export class SimpleField<T = any> extends MetaField {
    DefaultValue?: T
    Required?: boolean
    IsNullable?: boolean
};

export class StringField extends SimpleField<string> { static TypeId = 'string' };

export class IntegerField extends SimpleField<number> { static TypeId = 'int' };

export class DoubleField extends SimpleField<number> { static TypeId = 'double' };

export class ReferenceField extends SimpleField<number> { static TypeId = 'reference' };

export class BooleanField extends SimpleField<boolean> { static TypeId = 'bool' };

export class DateField extends SimpleField<Date> { static TypeId = 'date' };

export class DateTimeField extends SimpleField<Date> { static TypeId = 'datetime' };