import { Check, CheckRounded, ColorLens } from '@mui/icons-material';
import { IconButton, ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { currentTheme, setTheme, themes } from '.';

export default function ThemeMenu({ ...props }: React.HTMLAttributes<HTMLDivElement>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: any) => {
        setAnchorEl(null);

        const theme = (e.target as HTMLAnchorElement).getAttribute('data-theme') || '';

        setTheme(theme);
    };

    return <div {...props}>
        <IconButton
            title="Тема оформления"
            id="theme-button"
            aria-controls={open ? 'theme-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        ><ColorLens /></IconButton>
        <Menu
            id="theme-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'theme-button',
            }}
        >
            {themes.map(x => <MenuItem onClick={handleClose} {...{ "data-theme": x.id }} selected={x.id === currentTheme}>
                <ListItemIcon>{x.id === currentTheme ? <CheckRounded /> : null}</ListItemIcon>
                {x.name}
            </MenuItem>)}
        </Menu>
    </div>;
}