import { MetaField } from "./Field"

export default class CompositeField extends MetaField {
    static TypeId = 'composite'

    Fields!: MetaField[]
}

export class TableField extends CompositeField {
    static TypeId = 'table'
}

export function isCompositeField(cf?: MetaField) {
    return cf && ['field.composite', 'field.table'].includes(cf['$type']);
}
export function asCompositeField<TCF extends CompositeField = CompositeField>(cf?: MetaField) {
    return isCompositeField(cf) ? cf as TCF : null;
}