export enum DefaultEnum {

};

export enum ConnectionType {
	Url = 0,

	DB = 100,
	Postgres = 101,
	MsSql = 102,
	Oracle = 103,
	MySql = 104,

	RTA = 200,
	GoogleSpreadSheet = 201
}

export enum TemplateType {
	Archive = 0,
	CodeProject = 1
}