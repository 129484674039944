import moment from 'moment';
import 'moment/locale/ru';
import Route from './Router'
import { IdName } from './model/Types';
import './pages/entity';

moment.locale('ru');

Route.register('app');
Route.register('page');
Route.register('subpage', () => '');
Route.register('id', undefined, undefined, x => parseInt(x)?.toString() == x ? parseInt(x) : x);
Route.register('tab');
Route.register('copy', undefined, x => x ? 'true' : '', x => x ? x.toLowerCase() === 'true' ? true : false : undefined);
Route.register('path',
    () => [],
    x => x ? (x as IdName[]).map(x => x.Id + '_' + x.Name).join('/') : '',
    x => x ? x.split('/').map<IdName>(x => {
        return {
            Id: parseInt(x.split('_')[0]),
            Name: x.split('_').slice(1).join('_')
        }
    }) : []);
Route.register('returnUrl');
Route.register('showAll');

Route.addRoute('')
    .addUrlPath('app')
    .addUrlPath('page')
    .addUrlPath('id')
    .addUrlPath('tab');

export function appName(): 'graph' | 'data' | string {
    return Route.get<string>('app');
}

export const appIsGraph = () => appName() === 'graph';

export const appIsData = () => appName() === 'data';

//Route.setBaseUrl(window.location.origin + '/' + AppName);

Route.listen(r => {
    switch (appName()) {
        case 'data': document.title = 'ЕМД. Управление Данными'; break;
        case 'graph': document.title = 'ЕМД. Графика'; break;
        default: document.title = 'ЕМД. Технологии'; break;
    }
});

Route.hashChanged();