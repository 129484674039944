export const root = document.location.port == '3000' ? 'https://localhost:7225/' : '/';
export const rootHttp = document.location.port == '3000' ? 'http://localhost:5193/' : '/';

const buildQueryParams = (params: any, cmd: string) => params ? (cmd.indexOf('?') < 0 ? '?' : '&') + buildUrlQuery(params) : '';
export const buildUrl = (cmd: string, params?: any) => `${root}${cmd[0] === '/' ? '' + cmd.substring(1) : ('api/' + cmd)}${buildQueryParams(params, cmd)}`;

export function buildUrlQuery(obj: any) {
	var str = [];
	for (var p in obj) {
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	}

	return str.join("&");
}

const Proxy = {
	get(cmd: string, params?: any) {
		return fetch(buildUrl(cmd, params), {
			credentials: 'include'
		}).then(x => x.json());
	},

	getText(cmd: string, params?: any) {
		return fetch(buildUrl(cmd, params), {
			credentials: 'include'
		}).then(x => x.text());
	},

	post(cmd: string, body?: any, params?: any, config?: RequestInit) {
		return fetch(buildUrl(cmd, params), Object.assign({
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body),
			method: 'POST',
			credentials: 'include'
		}, config)).then(x => {
			if (x.status === 500) {
				return x.json().then(x => Promise.reject(x));
			} else {
				return x.json();
			}
		});
	}
};

export default Proxy;