import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import CheckBoxField from '../../components/fields/CheckBoxField';
import DataForm, { EntityApi, FormProxy } from '../../components/form/DataForm';
import { useRemoteData, useRoute } from '../../model/Hooks';
import { isSuper } from '../../model/Identity';
import { Role, User } from '../../model/Types';

const proxy: FormProxy<User, string> = {
    get: id => 'User/Get/' + id,
    save: e => 'User/Save'
}

const adminProxy: FormProxy<User, string> = {
    ...proxy,
    delete: e => 'User/Delete'
}

function RoleChecks({ user }: { user: User }) {
    const [roles] = useRemoteData<Role[]>("Role/List?showAll=true", null, 'controller');

    return <List>
        {roles?.map(x => <ListItem key={x.Id} disablePadding>
            <ListItemButton dense>
                <ListItemIcon>
                    <CheckBoxField
                        edge="start"
                        defaultChecked={user.Roles.includes(x.Name)}
                        tabIndex={-1}
                        disableRipple
                        onChange={e => {
                            if (e.target.checked) {
                                user.Roles.push(x.Name);
                            } else {
                                let ki = user.Roles.indexOf(x.Name);
                                if (ki >= 0) {
                                    user.Roles.splice(ki, 1);
                                }
                            }
                        }}
                    />
                </ListItemIcon>
                <ListItemText id={x.Id} primary={x.Name} />
            </ListItemButton>
        </ListItem>)}
    </List>
}

function RoleList({  user }: { user: User }) {
    return <List>
        {user.Roles?.map(x => <ListItem key={x}>
            <ListItemText primary={x} />
        </ListItem>)}
    </List>
}

export default function UserForm({ apiRef } : { apiRef?: (api: EntityApi<User, string>) => any }) {
    var [route] = useRoute();
    const id = route.get('id');

    return <DataForm<User, string> responseFormat="controller" id={id === 'new' ? '' : id} proxy={isSuper() ? adminProxy : proxy}>
        {(entity, api) => {
            apiRef && apiRef(api);
            return <>
                <Box gap={2} display="flex" flexDirection="column">
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <TextField label="Логин" name="userName" defaultValue={entity?.UserName} onChange={e => entity && (entity.UserName = e.target.value)} fullWidth />
                    </Box>

                    <TextField label="Пароль"
                        required={!entity.Id}
                        placeholder={entity?.Id ? "Оставьте поле пустым, если не хотите менять пароль" : ''}
                        name="password"
                        defaultValue={entity?.Password}
                        onChange={e => entity && (entity.Password = e.target.value)}
                        fullWidth />

                    <TextField label="ФИО" name="name" defaultValue={entity?.Name} onChange={e => entity && (entity.Name = e.target.value)} fullWidth />

                </Box>
                {
                    entity.Name === 'admin' ? null : <>
                        <Typography variant="h6">Роли</Typography>
                        {isSuper() ? <RoleChecks user={entity} /> : <RoleList user={entity} />}
                    </>
                }
            </>
        }}
    </DataForm>
}