import DataView from '../../components/DataView';
import { useRoute } from '../../model/Hooks';
import { ReactDashboard } from '../../model/Types';
import DashboardForm from './Form';
import DashboardsTable from './Table';

export default function DashboardsView() {
    var [route] = useRoute();
    return <DataView<number, ReactDashboard>
        table={(onSelectRow) => <DashboardsTable onSelectRow={onSelectRow} />}
        form={(id, apiRef) => <DashboardForm id={id} onSaved={e => route.setState('id', e.Id)} onDeleted={e => route.setState([['id']])} apiRef={apiRef} />}
    />;
}