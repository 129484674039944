import { ReactNode } from "react";
import CompositeField, { TableField } from "../../../model/meta/CompositeField";
import { BooleanField, DateField, DateTimeField, DoubleField, IntegerField, ReferenceField, StringField } from "../../../model/meta/Field";
import { TableColumn } from "../../table/DataTable";
import { FieldProps, FormFieldProps } from "./FieldProps";
import { BooleanFormField, CompositeFormField, DateFormField, DateTimeFormField, NumberFormField, SimpleFormField, TableFormField, } from "./Form";
import { booleanColumn, compositeColumn, dateColumn, dateTimeColumn, tableColumn } from "./Table";

export const fieldFormTypes: { [fieldType: string]: (props: FormFieldProps) => ReactNode } = {
    [StringField.TypeId]: (props: FormFieldProps) => <SimpleFormField {...props} />,
    [IntegerField.TypeId]: (props: FormFieldProps) => <NumberFormField {...props} />,
    [DoubleField.TypeId]: (props: FormFieldProps) => <NumberFormField {...props} />,
    [ReferenceField.TypeId]: (props: FormFieldProps) => <NumberFormField {...props} />,
    [BooleanField.TypeId]: (props: FormFieldProps) => <BooleanFormField {...props} />,
    [CompositeField.TypeId]: (props: FormFieldProps) => <CompositeFormField {...props} />,
    [TableField.TypeId]: (props: FormFieldProps) => <TableFormField {...props} />,
    [DateField.TypeId]: (props: FormFieldProps) => <DateFormField {...props} />,
    [DateTimeField.TypeId]: (props: FormFieldProps) => <DateTimeFormField {...props} />,
};

export const tableFormTypes: { [fieldType: string]: <T>(props: FieldProps) => TableColumn<T> } = {
    [BooleanField.TypeId]: booleanColumn,
    [DateField.TypeId]: dateColumn,
    [DateTimeField.TypeId]: dateTimeColumn,
    [CompositeField.TypeId]: compositeColumn,
    [TableField.TypeId]: tableColumn,
};

export function getFieldFn(typeId: string) {
    return fieldFormTypes['field.' + typeId];
}