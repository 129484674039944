import { useEffect, useState } from "react";
import route, { UrlRoute } from "../Router";
import { getUser, listenUser } from "./Identity";
import Observer from "./Observer";
import { getTenant, listenTenant } from "./Tenant";
import Proxy from "./Proxy";

export function useTenant() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant;
}

export function useTenantId() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant?.Id;
}

export function useUser() {
    const [user, setUser] = useState(getUser);
    useEffect(() => listenUser(setUser));
    return user;
}

export function useRoute(): [UrlRoute, string] {
    const [path, setPath] = useState(route.getPath());
    useEffect(() => route.listen(r => {
        var p = r.getPath();
        if (p != path) {
            setPath(p);
        }
    }), [path]);
    return [route, path];
}

const observers: { [name: string]: Observer } = {};
export function useObserver<TParams>(name: string, params?: TParams): [TParams | undefined, (params?: TParams) => void] {
    if (!observers[name]) {
        observers[name] = new Observer();
    }

    const [p, sp] = useState(params);
    useEffect(() => observers[name].listen('l', (p?: TParams) => sp(p)));
    return [p, p => observers[name].fire('l', [p])];
}

export type ResponseFormat = 'command' | 'controller' | 'local';

export type DataLoadCallback<T> = (data: T) => T;
export function useRemoteData<T>(url: string, callback?: DataLoadCallback<T> | null, responseFormat?: ResponseFormat | null, defaultData?: T)
    : [T | null, (loadUrl?: string) => Promise<T>] {
    const [data, setData] = useState<[string, T] | undefined>(defaultData ? [url, defaultData] : undefined);
    const [loading, setLoading] = useState<Promise<T>>();

    function load(loadUrl?: string) {
        if (loading) {
            return loading;
        }

        if (!loadUrl) {
            loadUrl = url;
        }

        function loaded(x: any) {
            if (callback) {
                x = callback(x as T);
            }

            setData([loadUrl!, x as T]);
            setLoading(undefined);

            return x as T;
        }

        if (responseFormat === 'local') {
            return Promise.resolve(loaded({}));
        }

        var req = Proxy.get(loadUrl).then(x => {
            switch (responseFormat) {
                case 'controller': break;

                case 'command':
                default:
                    x = x.result || x;
                    if (x.$type && x.$values) {
                        x = x.$values;
                    }

                    break;
            }

            return loaded(x);
        });

        setLoading(req);

        return req;
    }

    useEffect(() => {
        if (!data || data[0] !== url) {
            load();
        }
    }, [url, callback]);

    return [(data && data[1]) || null, load];
}