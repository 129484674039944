import MenuIcon from '@mui/icons-material/Menu';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import * as React from 'react';
import './App.css';
import route from './Router';
import { appName } from './Startup';
import { getUser, isAuthentificated, listenUser } from './model/Identity';
import { useMetas } from './model/meta/MetaStore';
import AppBar from './nav/AppBar';
import { menu, useAppRoute } from './nav/AppRoute';
import Breadcrumbs from './nav/Breadcrumbs';
import Drawer from './nav/Drawer';
import Login from './pages/Login';
import { useTheme } from './theme';
import ThemeMenu from './theme/ThemeMenu';
import { LogoDataW, LogoGrW } from './images/logo';

function DataBody({ children }: { children: () => React.ReactNode }) {
    const metas = useMetas();

    return <>{metas ? children() : null}</>;
}

const bodies: { [name: string]: (children: () => React.ReactNode) => React.ReactNode } = {
    graph: (x: () => React.ReactNode) => x(),
    data: (children: () => React.ReactNode) => <DataBody>{children}</DataBody>
}

function Body({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => any }) {
    const [, settings] = useTheme();
    const [, , , currentItem] = useAppRoute(menu);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const user = getUser();
    return <Box id="emdroot-wrapper" sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <CssBaseline />
        <AppBar id="appbar" position="absolute" open={open}>
            <Toolbar sx={{ pr: '24px', borderBottom: '1px solid', borderColor: 'divider' }} >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Breadcrumbs start={currentItem} />

                {settings?.AllowChangeTheme ? <ThemeMenu style={{ marginLeft: 'auto' }} /> : null}

                <Typography color="primary">{user.Name ? user.UserName ? user.Name + ' (' + user.UserName + ')' : user.Name : user.UserName}</Typography>
            </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} onToggle={toggleDrawer} />
        <Box
            component="main"
            data-page={currentItem.id}
            data-path-route={route._getPathRoute()?.prefix}
            sx={{
                backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey[900],
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
            }}
        >
            <Toolbar />
            {currentItem?.element && currentItem?.element()}
        </Box>
    </Box>;
}

const products = [
    { name: 'graph', title: 'ЕМД. Графика', logo: <LogoGrW /> },
    { name: 'data', title: 'ЕМД. Данные', logo: <LogoDataW /> }
]

function AppSelector() {
    var lps = getUser().License?.Products,
        ps = products.filter(lps?.length ? p => lps!.find(lp => lp === p.name) : x => true);

    if (ps.length === 1) {
        let path = route.getPath();
        requestAnimationFrame(() => {
            if (path === route.getPath()) {
                route.setPath('/' + ps[0].name)
            }
        });
    }

    return <div id='login-wrapper'>
        {ps.map(p => <Paper component="a" href={'/' + p.name} sx={styles.selector} elevation={2} title={p.title}>{p.logo}</Paper>)}
    </div>
}

export default function App() {
    const [open, setOpen] = React.useState(true);
    const [apn, setApp] = React.useState(appName());
    const [auth, setAuth] = React.useState(isAuthentificated());
    const [theme] = useTheme();

    React.useEffect(() => listenUser(() => {
        var a = isAuthentificated();
        if (a != auth) {
            setAuth(a);
        }
    }));

    React.useEffect(() => route.listen(() => {
        var a = appName();
        if (a !== apn) {
            setApp(a);
        }
    }));

    if (theme) {
        return <ThemeProvider theme={theme.theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                {bodies[apn] ?
                    auth && (route.get('page') !== 'login') ?
                        bodies[apn](() => <Body open={!!open} setOpen={setOpen} />) :
                        <Login /> :
                    <AppSelector />}
            </LocalizationProvider>
        </ThemeProvider>;
    } else {
        return <></>;
    }
}


const styles = {
    selector: {
        p: 5,
        alignItems: 'center',
        borderRadius: '2em',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        maxWidth: 420,
        '& svg': {
            height: 200
        }
    }
};