import { Theme, createTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import Observer from '../model/Observer';
import './mpt/mpt.css';
import { useRemoteData } from '../model/Hooks';
import { Settings } from '../model/Types';

export interface EmdTheme extends Theme {
    id?: string,
    checkbox?: 'checbox'|'switch'|'ios'
}

interface ThemeInfo {
    id: string
    name: string
    theme: EmdTheme
}
export const themes: ThemeInfo[] = [
    {
        id: 'default',
        name: 'Стандартная',
        theme: createTheme({
            components: {
                MuiTextField: {
                    defaultProps: {
                        variant: 'standard'
                    }
                }
            },
            palette: {
                info: {
                    main: '#282828'
                }
            }
        }) as EmdTheme
    },
    {
        id: 'mpt',
        name: 'МПТ',
        theme: Object.assign(createTheme({
            palette: {
                text: {
                    primary: '#000'
                },
                error: {
                    main: '#FF0032'
                },
                success: {
                    main: '#669966'
                },
                primary: {
                    main: '#0099FF'
                }
            },
            typography: {
                fontSize: 16,
                button: {
                    textTransform: 'none'
                },
                h6: {
                    fontSize: 16,
                    fontWeight: 700
                }
            },
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            borderRadius: 8
                        },
                        input: {
                            fontSize: '1rem',
                            lineHeight: '1.2rem',
                            padding: 14
                        }
                    }
                },
                MuiPaper: {
                    defaultProps: {
                        elevation: 0
                    }
                },
                MuiTableContainer: {
                    styleOverrides: {
                        root: {
                            padding: '0 1.5rem'
                        }
                    }
                },
                MuiTextField: {
                    defaultProps: {
                        variant: 'outlined'
                    }
                },
                MuiSwitch: {
                    defaultProps: {
                        color: 'primary'
                    }
                },
                MuiToolbar: {
                    
                }
            }
        }), {
            id: 'mpt',
            checkbox: 'ios'
        }) as EmdTheme
    }
];

export var currentTheme: string;
const themeObserver = new Observer();

var themeSettings: Settings | null;
export function setTheme(id?: string | null) {
    const old = currentTheme;
    currentTheme = id || 'default';
    if (themeSettings?.AllowChangeTheme) {
        localStorage.setItem('emdtheme', currentTheme);
    }

    document.body.setAttribute('data-emdtheme', currentTheme);

    if (old !== currentTheme) {
        themeObserver.fire('change', [currentTheme]);
    }
}

export function getTheme() {
    return themes.find(x => x.id === currentTheme);// || themes[0];
}

export function useThemeSettings() {
    [themeSettings] = useRemoteData<Settings>('Settings/Get?type=Theme', undefined, 'controller');

    if (!currentTheme && themeSettings) {
        setTheme((themeSettings.AllowChangeTheme ? localStorage.getItem('emdtheme') : null) || themeSettings.DefaultTheme);
    }

    return themeSettings;
}

export function useTheme(): [ThemeInfo | undefined, Settings | null] {
    const [theme, setTheme] = useState(currentTheme);
    const settings = useThemeSettings();
    useEffect(() => themeObserver.listen('change', (x: string) => setTheme(x)));
    return [getTheme(), settings];
}