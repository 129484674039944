import { ReactNode } from "react";
import { menu } from "../../nav/AppRoute";
import { useRemoteData } from "../Hooks";
import { Metadata } from "../Types";

var metas: Metadata[];

export function getMetas() {
    return metas;
}
export function getMetaByTypeId(typeId:string) {
    return metas.find(x => x.TypeId === typeId);
}

export function useMetas() {
    return useRemoteData<Metadata[]>('MetadataGetAll', x => {
        metas = x;
        updateStore();
        return x;
    }, null, metas)[0];
}

export function saveMeta(meta: Metadata) {
    if (!metas) {
        metas = [];
    }

    const index = metas.findIndex(x => x.TypeId === meta.TypeId);
    if (index >= 0) {
        metas[index] = meta;
    } else {
        metas.push(meta);
    }

    updateStore();
}

export function deleteMeta(id: number) {
    metas?.splice(metas?.findIndex(x => x.Id === id), 1);

    updateStore();
}

var entityViewRenderer: (metadata:Metadata) => ReactNode;
export function setEntityView(renderer: (metadata: Metadata) => ReactNode) {
    entityViewRenderer = renderer;

    updateStore();
}
function updateStore() {
    while (true) {
        let index = menu.findIndex(x => x?.id?.startsWith('_meta_'));
        if (index >= 0) {
            menu.splice(index, 1);
        } else {
            break;
        }
    }

    if (entityViewRenderer) {
        metas?.forEach(x => menu.push({
            id: '_meta_' + x.TypeId,
            filter: () => false,
            typeId: x.TypeId,
            element: () => entityViewRenderer(x),
            path: x.TypeId + '/*',
            title: x.Name
        }));
    }

    return metas;
}