import { AccountCircle, Key, Visibility, VisibilityOff } from '@mui/icons-material';
import { FormHelperText, IconButton, InputAdornment, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import route from '../Router';
import { useRoute } from '../model/Hooks';
import { isAuthentificated, isInit, login, register } from '../model/Identity';
import './login.css';
import { LogoW } from '../images/logo';

route.register('login')

const text: any = {
    login: 'Войти',
    register: 'Зарегистрироваться'
}

export default function Login() {
    const [route, path] = useRoute();
    const [error, setError] = useState<string>();
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();

    const mode = route.get('login');

    const submit = (e: any) => {
        const userName = e.target.querySelector('[name=userName]').value,
            password = e.target.querySelector('[name=password]').value;

        if (mode !== 'register' || (document.location.port != '3000' && document.location.hash != '#allowregister')) {
            login('/UserName', { userName, password }).then(x => {
                if (isAuthentificated()) {
                    const returnUrl = route.get('returnUrl');
                    if (returnUrl) {
                        document.location = returnUrl;
                    } else {
                        route.setState('login');
                    }
                } else {
                    setError('Неверный логин/пароль');
                }
            });
        } else {
            register(userName, password, e.target.querySelector('[name=password2]').value)
                .then(x => {
                    if (isAuthentificated()) {
                        route.setState([['guide', 'welcome'], ['login']]);
                    } else switch (x.result?.errors?.map((x: any) => x.code)[0]) {
                        case 'DuplicateUserName': setError('Пользователь с таким логином уже существует'); break;
                        default: setError('Некорректные данные'); break;
                    }
                });
        }

        setError(undefined);

        e.stopPropagation();
        e.preventDefault();
    }

    let items: React.ReactNode;
    if (isInit()) {
        items = <>
            <OutlinedInput name="userName" placeholder="Логин" sx={styles.input}
                fullWidth
                inputProps={{
                    style: {
                        paddingLeft: '3em'
                    }
                }}
                startAdornment={
                    <InputAdornment position="start" sx={{ position: 'absolute', pl: 2 }} >
                        <AccountCircle />
                    </InputAdornment>} />

            <OutlinedInput name="password" type={showPassword ? 'text' : 'password'} placeholder="Пароль" sx={styles.input}
                fullWidth
                inputProps={{
                    style: {
                        paddingLeft: '3em',
                        paddingRight: '4em'
                    }
                }}
                startAdornment={
                    <InputAdornment position="start" sx={{ position: 'absolute', pl: 2 }}>
                        <Key />
                    </InputAdornment>}
                endAdornment={
                    <InputAdornment position="end" sx={{ position: 'absolute', right: 0, pr: 3 }}>
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>} />
            {error && mode !== 'register' ? <FormHelperText error>{error}</FormHelperText> : null}

            {mode == 'register' ?
                <FormControl fullWidth>
                    <FormLabel>Подтвердите пароль</FormLabel>
                    <OutlinedInput name="password2" type="password" />
                    {error ? <FormHelperText error>{error}</FormHelperText> : null}
                </FormControl> :
                null}

            <Button variant="contained" type='submit' fullWidth sx={styles.button}>{text[mode || 'login']}</Button>

            {/**
            <Typography fontSize="sm" sx={styles.typography}>
                <a href="#" style={{ color: theme.palette.action.active }}>Забыли пароль?</a>
            </Typography>

            {mode != 'register' ? <Typography fontSize="sm" sx={styles.typography} style={{ marginTop: 48 }}>Еще не ВКлубе?</Typography> : null}
            <Typography fontSize="sm" sx={styles.typography}>
                <a href="#"
                    onClick={() => route.setState([['login', mode == 'register' ? 'login' : 'register']])}
                    style={{ color: theme.palette.error.main }}>
                    {mode == 'register' ? text.login : text.register}
                </a>
            </Typography>
            /**/}
        </>;
    }

    return <div id='login-wrapper'>
        <Paper component="form" onSubmit={submit} sx={styles.form} elevation={2}>
            <LogoW style={{ height: '200px' }} />

            {items}
        </Paper>
    </div>;
}

const styles = {
    form: {
        p: 5,
        borderRadius: '2em',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        maxWidth: 420,
        alignItems: 'center'
    },
    button: {
        color: 'white',
        backgroundColor: '#2A2A2A',
        px: 3,
        py: 1.5,
        borderRadius: '2em'
    },
    typography: {
        alignSelf: 'center'
    },
    input: {
        padding: 0,
        maxWidth: '100%',
        color: '#2A2A2A',
        borderColor: '#2A2A2A',
        backgroundColor: '#F5F7FA',
        borderRadius: '2em'
    }
}